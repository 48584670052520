<template>
  <div>
    <DeleteModal :deleteModal="deleteModal" @init="init()"></DeleteModal>
    <div v-if="isPermission==false">資料讀取中</div>
    <div v-else-if="permission.isView">
      <div v-if="!detail">
        <b-row
          ><b-col>
            <b-form-input
              v-model="search.keywords"
              placeholder="請輸入關鍵字"
              maxlength="100"
            ></b-form-input></b-col
          >
          <b-col>
            <b-form-datepicker
              placeholder="請選擇開始日期"
              v-model="search.startDate"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          ~
          <b-col>
            <b-form-datepicker
              placeholder="請選擇結束日期"
              v-model="search.OverDate"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col cols="1"
            ><b-button @click="init">
              <b-icon icon="search"></b-icon></b-button
          ></b-col>
          <b-col cols="2"
            ><b-button 
              @click="_go('?type='+typeSelect+'&id=null')"
              v-if="permission.isInsert"
              >新增</b-button
            ></b-col
          ></b-row
        >
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>問題</b-th>
              <b-th>異動人員</b-th>
              <b-th>異動日期</b-th>
              <b-th v-if="permission.isDelete || permission.isView"
                >修改</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in contentList" :key="item.contentId">
              <b-td>{{ item.title }}</b-td>
              <b-td>{{ item.modifierName }}</b-td>
              <b-td>{{ dateFormat(item.modifyDate) }}</b-td>
              <b-td v-if="permission.isDelete || permission.isView">
                <b-button
                  v-if="permission.isUpdate"
                  variant="info"
                  class="mr-3"
                  @click="_go('?type='+typeSelect+'&id=' + item.contentId)"
                  >{{ (permission.isUpdate || permission.isInsert)  ? "編輯" : "檢視" }}</b-button
                >
                <a
                  href="javascript:"
                  v-if="permission.isDelete"
                  @click="functionDelete(item)"
                  ><img src="/images/backend/del_icon.png" alt="刪除icon"/></a
              ></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div class="page mt-2">
          <span class="pagination-total">共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料</span>
          <b-pagination
            class="pagination light-theme simple-pagination"
            v-model="pagination.page"
            :total-rows="pagination.rows"
            :per-page="pagination.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
      <div v-else>
        <div class="incsal">
          <div class="btare">
            <div class="backrul">
              <a :href="'#/backend/FAQ/?type=' + typeSelect">返回查詢結果</a>
            </div>
          </div>
          <b-row>
            <b-col
              ><label for="title">標題*</label>
              <b-form-input
                id="title"
                v-model="contentDetail.title"
                type="text"
                placeholder="請輸入標題"
                required
                maxlength="80"
              ></b-form-input
            ></b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>上架時間*</label>
              <b-form-datepicker 
                v-model="contentDetail.startDate"
                class="mb-2"
              ></b-form-datepicker
            ></b-col>
            <b-col>
              <label>下架時間</label>
              <b-form-datepicker
                v-model="contentDetail.endDate"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
            <b-col
              ><label>發布狀態*</label>
              <b-form-select
                v-model="contentDetail.deployStatus"
                :options="statusOptions"
              ></b-form-select
            ></b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>內文</label>
              <TinyMceEditor IsPrivate="false" v-model="contentDetail.description"></TinyMceEditor>
            </b-col>
          </b-row>
          <b-row
            ><b-col>
              <label>建檔人員</label>
              <b-form-input
                disabled="disabled"
                v-model="contentDetail.creatorName"
                class="mb-2"
              ></b-form-input></b-col
            ><b-col>
              <label>建檔日期</label>
              <b-form-datepicker
                disabled="disabled"
                v-model="contentDetail.createDate"
                class="mb-2"
              ></b-form-datepicker></b-col
          ></b-row>
          <b-row
            ><b-col>
              <label>異動人員</label>
              <b-form-input
                disabled="disabled"
                v-model="contentDetail.modifierName"
                class="mb-2"
              ></b-form-input></b-col
            ><b-col>
              <label>異動日期</label>
              <b-form-datepicker
                disabled="disabled"
                v-model="contentDetail.modifyDate"
                class="mb-2"
              ></b-form-datepicker></b-col
          ></b-row>
          <div v-if="permission.isInsert || permission.isUpdate" style="margin-top: 10px">
            <b-button @click="save">儲存</b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>無權限</div>
  </div>
</template>
<script>
  import TinyMceEditor from "../tinymce-editor/tinymce-editor.vue";
  import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
  import DeleteModal from "../modal/deleteModal.vue";
  export default {
    components: {
      TinyMceEditor,
      DeleteModal,
    },
    props: [ "contentId"],
    data() {
      return {
        typeSelect : 3,
        detail: false,
        search: {},
        initSeach: false,
        pagination: {
          perPage: 10,
          page: 1,
          rows: 5,
        },
        contentList: [],
        contentDetail: {},
        deleteModal: {
          id: null,
          show: false,
          type: "ContentFAQ",
        },
        statusOptions: [
          { value: 1, text: '開啟' },
          { value: 2, text: '關閉' },
        ],
        apiContent: (data) => this.userRequest.post("Eshntu/Content", data),
      };
    },
    computed: {},
    mounted() {
      this.init();
    },
    watch: {
      "$route.query"() {
        this.init();
      },
      "pagination.page"() {
        this.init();
      },
    },
    methods: {
      init() {
        if (this.typeSelect == undefined) {
          this.permission.isView = false;
        }

        this.getPermission("PJ202409010033");

        if (this.contentId == undefined) {
          window.scrollTo(0, 0);
          this.detail = false;  
          this.initSeach = true;  
          this.contentList = {};
                        
          let json = {
            TYPE: "LIST",
            content_SELECT: {
              contentTypeId: this.typeSelect,
              keywords: this.search.keywords,
              pageIndex: this.pagination.page,
              pageSize: this.pagination.perPage,
            },
          };
          this.FunctionToken(this.GetContent, json);
        } else if (this.contentId == "null") {
          this.detail = true;  
          this.initSeach = false; 

          this.contentDetail = {
            contentTypeId: this.typeSelect,
            startDate: new Date().toISOString(),
            deployStatus: 2,
            creatorName: sessionStorage.getItem("LoginName"),
            createDate: new Date(),
            modifierName: sessionStorage.getItem("LoginName"),
            modifyDate: new Date(),
          };
        } else {
          this.detail = true;  
          this.initSeach = false; 
          this.contentDetail = {};

          let json = {
            TYPE: "SELECT",
            content_SELECT: {
              contentId: this.contentId,
              contentTypeId: this.typeSelect,
            },
          };
          this.FunctionToken(this.GetContentDetail, json);
        }
      },
      GetContent(data) {
        this.apiContent(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.contentList = jsonData.outData;
              this.pagination.rows = jsonData.totalCount;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      GetContentDetail(data) {
        this.apiContent(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.contentDetail = jsonData;
              
              this.setTinymceContent(this.contentDetail.description);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      functionDelete(item) {
        this.deleteModal.show = true;
        this.deleteModal.id = item.contentId;
        this.deleteModal.type = "ContentFAQ";
      },
      save() {
        this.contentDetail.description = this.getTinymceContent();

        if (this.contentId == "null") {
          let json = {
            TYPE: "INSERT",
            content_INSERT: {
              contentTypeId: this.typeSelect,
              contentClassId: 14,
              title: this.contentDetail.title,
              description: this.contentDetail.description,
              startDate: this.contentDetail.startDate,
              endDate: this.contentDetail.endDate,
              deployStatus: this.contentDetail.deployStatus,
            },
          };
          this.FunctionToken(this.InsertContent, json);
        } else {
          let json = {
            TYPE: "UPDATE",
            content_UPDATE: {
              contentId: this.contentId,
              contentTypeId: this.typeSelect,
              contentClassId: 14,
              title: this.contentDetail.title,
              description: this.contentDetail.description,
              startDate: this.contentDetail.startDate,
              endDate: this.contentDetail.endDate,
              deployStatus: this.contentDetail.deployStatus,
            },
          };
          this.FunctionToken(this.UpdateContent, json);
        }
      },
      InsertContent(data) {
        this.apiContent(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.$bvToast.toast("新增成功", {
                title: "提示",
                solid: true,
              });
              this.pagination.page = 1;
              this._go("?type=" + this.typeSelect);
            } else {
              alert(JSON.parse(json.Data));
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      UpdateContent(data) {
        this.apiContent(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this.$bvToast.toast("更新成功", {
                title: "提示",
                solid: true,
              });
              this._go("?type=" + this.typeSelect);
            } else {
              alert(JSON.parse(json.Data));
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      setTinymceContent(data) {
          setTimeout(function () {
            getTinymce().activeEditor.setContent(data);
          }, 500);
      },
      getTinymceContent() {
          return getTinymce().activeEditor.getContent();
      },
    },
  };
</script>
