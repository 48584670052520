<template>
  <div>
    <div class="title mb-3">
      <span class="title-01">{{ header.text1 }}</span>
      <span class="title-02" v-if="header.text2 != null"
        >－{{ header.text2 }}</span
      >
    </div>
    <b-breadcrumb style="background-color: #e7e7e7 !important">
      <b-breadcrumb-item active>{{ header.text1 }}</b-breadcrumb-item>
      <b-breadcrumb-item active v-if="header.text2 != null">{{
        header.text2
      }}</b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  props: ["header"],
  mounted() {
    this.getPage();
  },
  watch: {
  },
  methods: {
    getPage() {
      let page = this.$route.name;
    },
  }
};
</script>
<style scoped>
.title {
  background: linear-gradient(
    0deg,
    #c4c4c4 0%,
    #ececec 50%,
    #ececec 100%
  ) !important;
  border: none;
  color: #000 !important;
  text-align: center;

  line-height: 1;
  padding: 0.5rem 0;
  border-width: 1px 0;
  border-color: #c4c4c4;
  border-style: solid;
}
.title .title-01 {
  font-size: 1.2em;
}
.title .title-01 {
  font-size: 1.4em;
}
.title .title-02 {
  font-size: 0.25rem;
}
</style>