<template>
  <div>

  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: ["header"],
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    init() {
      const createjsPlugin = document.createElement("script");
      createjsPlugin.setAttribute(
      "src",
      "/js/createjs.min.js"
      );
      createjsPlugin.async = false;
      document.head.appendChild(createjsPlugin);

      const indexPlugin = document.createElement("script");
      indexPlugin.setAttribute(
      "src",
      "/js/index.js"
      );
      indexPlugin.async = false;
      document.head.appendChild(indexPlugin);
    },
  },
};
</script>
