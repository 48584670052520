<template>
  <div>
    <div class="intopbg"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: ["header"],
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    init() {
      document.body.classList.add('inpgcn');
    },
  },
};
</script>
