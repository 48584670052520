<template>
  <div>
    <nav class="navbar Customize navbar-light">
      <a class="navbar-brand" href="#/backend"
        ><img
          src="/images/favicon/apple-touch-icon.png"
          alt="首頁圖示"
          class="logo_01"
        />
        國立臺灣大學環境保護暨職業安全衛生中心 — 後台管理系統</a
      >
    </nav>
    <div class="sub-title"><span>歡迎登入！<a href="/#/backend/Account/?type=9">{{ userName }}</a></span></div>
    <b-container fluid>
      <b-row>
        <b-col cols="auto">
          <div style="width: 320px">
            <SideBar></SideBar>
          </div>
        </b-col>
        <b-col>
          <router-view />
        </b-col>
      </b-row>
    </b-container>

    <b-modal v-model="logOut" title="提示" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true" hide-header no-stacking>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click="goHome"
        >
          確定
        </button>
      </template>
      <p class="my-4">{{ message }}</p>
    </b-modal>    
    <b-modal v-model="logOutConfirm" title="提示" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true" hide-header no-stacking>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click="goLogIn"
        >
          繼續操作
        </button>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click="goLogOut"
        >
          直接登出
        </button>
      </template>
      <p class="my-4">{{ message }}</p>
    </b-modal>
  </div>
</template>

<script>
import SideBar from "@/components/admin/NavBar/sideBar.vue";

export default {
  name: "index_admin",
  components: {
    SideBar,
  },
  data() {
    return {
      userName: "",
      message: null,
      logOut: false,
      logOutConfirm: false,
      timeOutRefresh: null,
      apiBackendLogin: (data) =>
        this.userRequest.post("Eshntu/BackendLogin", data),
    };
  },
  mounted() {
    this.adminCheckLogin()
  },
  watch: {
    "$route.query"() {
      this.adminCheckLogin()
    },
  },
  methods: {
    adminCheckLogin() {
      clearInterval(this.timeOutRefresh);
      this.timeOutRefresh = setInterval(() => {
        this.CheckLogin();
      }, 5000);
      this.userName = sessionStorage.getItem("LoginName");
    },
    CheckLogin() {
        let json = {
          TYPE: "CHECKSESSION",
          login_SELECT: {},
        }
        this.FunctionToken(this.functionCheckLogin, json);
    },
    functionCheckLogin(data) {
      this.apiBackendLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (!json.Status) {
            window.clearInterval(this.timeOutRefresh);
            if(json.Data == "logconfirm") {
              this.message = "您即將登出，是否繼續操作或是直接登出";
              this.logOutConfirm = true;
            } else {
              this.message = "尚未登入";
              this.logOut = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goHome() {
      this._go("/ISY");
    },
    goLogIn() {
      let json = {
        TYPE: "CONTINUEWORK",
        login_SELECT: {},
      }
      this.FunctionToken(this.functionContinueWork, json);  
    },
    functionContinueWork(data) {
      this.apiBackendLogin(data)
        .then((res) => {
          this.logOutConfirm = false;
          const json = JSON.parse(res.data);
          if (!json.Status) {
            this.message = "已超過登入時間，已登出系統";
            this.logOut = true;
          } else 
            this._go(this.$route.fullPath);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goLogOut() {
      let json = {
        TYPE: "LOGOUT",
        login_SELECT: {},
      }
      this.FunctionToken(this.functionLogOut, json);
    },
    functionLogOut(data) {
      this.apiBackendLogin(data)
        .then((res) => {
          this.logOutConfirm = false;
          const json = JSON.parse(res.data);

          this._go("/ISY");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
</script>
<style lang="css" scoped>
.Customize {
  background: linear-gradient(180deg, #92b267 0%, #54792b 100%) !important;
  line-height: 1 !important;
  height: auto;
}
.Customize .navbar-brand {
  color: #fff;
  font-size: 2.2em;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.Customize .logo_01 {
  height: 64px;
  width: auto;
  margin-right: 10px;
}
.sub-title {
  /*background-color: #e7e7e7;*/
  font-size: 2.2em;
  /*text-align: center;
  padding: 0.5rem 0;
  margin-bottom: 1rem;*/
}
.sub-title span {
  font-size: 1rem;
  padding: 30px 0 0 40px;
  font-weight: bold;
  color: cadetblue;
}
</style>