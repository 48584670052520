<template>
  <div>
    <div v-if="isPermission==false">資料讀取中</div>
    <div v-else-if="permission.isView">
      <div v-if="!detail">
        <b-table-simple :borderless="true" class="mb-0"> 
          <b-tr >
            <b-td class="w-50">
              Google登入請使用以下資訊：
            </b-td>
          </b-tr> 
          <b-tr >
            <b-td class="w-50">
              <label>公用Google帳號</label>
              <b-form-input
                v-model="contentDetail.Account"
                :disabled="true"
              ></b-form-input>
            </b-td>
            <b-td class="w-50" rowspan="2">
              <b-button
                variant="info"
                class="btn-lg py-4"
                @click="_go('?type=10&id=99')"
                v-if="permission.isInsert || permission.isUpdate"
              >設置公用帳號</b-button>
            </b-td>
          </b-tr>
          <b-tr >
            <b-td class="w-50">
              <label>公用Google密碼</label>
              <b-form-input
                v-model="contentDetail.Pwd"
                :disabled="true"
              ></b-form-input>
            </b-td>
          </b-tr>
          <b-tr >
            <b-td class="w-50">
              問卷功能請使用Google表單系統 
              <b-button
                variant="primary"
                @click="_go1('https://docs.google.com/forms/u/0/', 1, 'google doc')"
                >前往Google表單</b-button>
            </b-td>
          </b-tr>
        </b-table-simple>

        <b-container>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>公用Google帳號</b-th>
                <b-th>設置人員</b-th>
                <b-th>設置日期</b-th>
                <b-th>異動人員</b-th>
                <b-th>異動日期</b-th>
                <b-th>備註</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(item, index) in contentDetail.Log" :key="index">
                <b-td>{{ item.Account }}</b-td>
                <b-td>{{ item.creatorName }}</b-td>
                <b-td>{{ dateFormat(item.createDate) }}</b-td>
                <b-td>{{ item.modifierName }}</b-td>
                <b-td>{{ dateFormat(item.modifyDate) }}</b-td>
                <b-td>{{ item.note }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="page mt-2">
            <span class="pagination-total">共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料</span>
            <b-pagination
              class="pagination light-theme simple-pagination"
              v-model="pagination.page"
              :total-rows="pagination.rows"
              :per-page="pagination.perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-container>
      </div>
      <div v-else>
        <div class="btare">
          <div class="backrul">
            <a href="#/backend/Account/?type=10">返回查詢結果</a>
          </div>
        </div>
        <b-row>
          <b-col>
            <label>公用Google帳號*</label>
            <b-form-input
              v-model="contentDetail.Account"
              maxlength="20"
              required
              placeholder="請輸入公用Google帳號"
            ></b-form-input>
          </b-col>
          <b-col>
            <label>公用Google密碼*</label>
            <b-form-input
              v-model="contentDetail.Pwd"
              maxlength="50"
              required
              placeholder="請輸入公用Google密碼"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>本次變更設置原因*</label>
            <b-form-textarea
              v-model="contentDetail.note"
                rows="5"
                max-rows="10"
              ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row
          ><b-col>
            <label>建檔人員</label>
            <b-form-input
              :disabled="true"
              v-model="contentDetail.creatorName"
              class="mb-2"
            ></b-form-input></b-col
          ><b-col>
            <label>建檔日期</label>
            <b-form-input
              :disabled="true"
              v-model="contentDetail.createDate"
              class="mb-2"
            ></b-form-input></b-col
        ></b-row>
        <b-row
          ><b-col>
            <label>異動人員</label>
            <b-form-input
              :disabled="true"
              v-model="contentDetail.modifierName"
              class="mb-2"
            ></b-form-input></b-col
          ><b-col>
            <label>異動日期</label>
            <b-form-datepicker
              :disabled="true"
              v-model="contentDetail.modifyDate"
              class="mb-2"
            ></b-form-datepicker></b-col
        ></b-row>
        <div v-if="permission.isInsert || permission.isUpdate">
          <b-button @click="save">儲存</b-button>
        </div>
      </div>
    </div>
    <div v-else>無權限</div>
  </div>
</template>

<script>
const filesystem = require('fs');
export default {
  data() {
    return {
      detail: false,
      pagination: {
        perPage: 8,
        page: 1,
        rows: 5,
      },
      contentDetail: {},
      apiPublicAccount: (data) => this.userRequest.post("Eshntu/PublicAccount", data),
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    init() {
      this.getPermission("PJ202409010023");
      
      this.id = this.$route.query.id;
      if (this.id == undefined) {
        this.detail = false;
        let json = {
          TYPE: "SELECT",
        };
        this.FunctionToken(this.GetDetail, json);
      } else if (this.id == "null") {
        this.detail = true;
        
        this.contentDetail = { 
          Account: "", 
          Pwd: "",          
          creatorName: sessionStorage.getItem("LoginName"),
          modifierName: sessionStorage.getItem("LoginName"),
          createDate: new Date(),
          modifyDate: new Date(),
          Log: [],
        };
      } else {
        this.detail = true;
        
        this.contentDetail.modifierName = sessionStorage.getItem("LoginName");
        this.contentDetail.modifyDate = new Date();
        this.contentDetail.note = "";
      }
    },
    save() {
        let json = {
          TYPE: "UPDATE",
          public_account_UPDATE: this.contentDetail,
        };
        this.FunctionToken(this.UpdateData, json);
    },
    UpdateData(data) {
      this.apiPublicAccount(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.$bvToast.toast("已設置完成", {
              title: "提示",
              solid: true,
            });
            this._go("?type=10");
          } else {
            alert(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetDetail(data) {
      this.apiPublicAccount(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);

            this.contentDetail = jsonData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
