var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"fatfooter"},[_vm._m(0),_c('div',{staticClass:"fatfcon"},[_c('div',{staticClass:"fatbkal"},[_vm._m(1),_c('div',{staticClass:"fatbck"},[_vm._m(2),_c('div',{staticClass:"fatfh2al"},_vm._l((_vm.List_6_14),function(item){return _c('div',{key:item.businessId,staticClass:"fatfh2"},[_c('a',{attrs:{"href":'#/Police/Content?type='+'14'+'&L1='+item.key+'&id='+item.key}},[_vm._v(_vm._s(item.value))])])}),0)]),_c('div',{staticClass:"fatbck"},[_vm._m(3),_c('div',{staticClass:"fatfh2al"},_vm._l((_vm.List_7_15),function(item){return _c('div',{key:item.businessId,staticClass:"fatfh2"},[_c('a',{attrs:{"href":'#/Police/Content?type='+'15'+'&L1='+item.key+'&id='+item.key}},[_vm._v(_vm._s(item.value))])])}),0)]),_c('div',{staticClass:"fatbck"},[_vm._m(4),_c('div',{staticClass:"fatfh2al"},_vm._l((_vm.List_8_16),function(item){return _c('div',{key:item.businessId,staticClass:"fatfh2"},[_c('a',{attrs:{"href":'#/Police/Content?type='+'16'+'&L1='+item.key+'&id='+item.key}},[_vm._v(_vm._s(item.value))])])}),0)])]),_vm._m(5)])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"fotcon"},[_vm._m(6),_c('div',{staticClass:"fotc_right"},[_vm._v(" ©2024 臺灣大學環安衛中心 版權所有"),_c('br'),_vm._v(" 總瀏覽人數："+_vm._s(_vm.totalCount)+" "),(!_vm.footer.index)?_c('span',[_vm._v(" / 本頁瀏覽人數："+_vm._s(_vm.pageCount))]):_vm._e(),_vm._v("     更新日期："+_vm._s(_vm.dateFormatFloat(_vm.updateDate))+" "),_c('br'),_vm._v(" 最佳解析度 1920*1080 建議瀏覽器：Chrome、Edge ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"illpc02"},[_c('img',{attrs:{"src":"/images/police/inbmill.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fatbck"},[_c('div',{staticClass:"fatfh1"},[_vm._v("消息與資源")]),_c('div',{staticClass:"fatfh2al"},[_c('div',{staticClass:"fatfh2"},[_c('a',{attrs:{"href":"#/Police/News"}},[_vm._v("最新消息")])]),_c('div',{staticClass:"fatfh2"},[_c('a',{attrs:{"href":"#/Police/Lose"}},[_vm._v("失物招領")])]),_c('div',{staticClass:"fatfh2"},[_c('a',{attrs:{"href":"#/Police/FAQ"}},[_vm._v("常見問題")])]),_c('div',{staticClass:"fatfh2"},[_c('a',{attrs:{"href":"#/Police/Download"}},[_vm._v("下載專區")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fatfh1"},[_c('a',{attrs:{"href":"#/Police/Content?type=14"}},[_vm._v("關於本隊")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fatfh1"},[_c('a',{attrs:{"href":"#/Police/Content?type=15"}},[_vm._v("服務項目")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fatfh1"},[_c('a',{attrs:{"href":"#/Police/Content?type=16"}},[_vm._v("常用連結")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fatinf"},[_vm._v(" 環安衛中心駐衛警察隊電話：02-3366-2185-6 / 傳真：02-2369-6474 "),_c('p',[_vm._v("24小時緊急報案專線電話：02-3366-9110")]),_c('p',[_vm._v("電子信箱：police@ntu.edu.tw")]),_c('p',[_vm._v("地址：10617 臺北市大安區羅斯福路四段一號 展書樓1樓"),_c('a',{staticClass:"mapico",attrs:{"href":"https://maps.app.goo.gl/7xjP5Qr62BuRayoR8","target":"_blank","title":"環安衛中心駐衛警察隊所在位置,開啟GOOGLE地圖(另開新視窗)"}},[_c('img',{attrs:{"src":"/images/police/mapico.png","align":"absmiddle","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fotc_left"},[_c('div',{staticClass:"ftntulog"},[_c('img',{attrs:{"src":"/images/police/ntulogo.svg","alt":""}})]),_c('div',{staticClass:"ftmlog"},[_c('img',{attrs:{"src":"/images/police/foter_title.svg","alt":""}})])])
}]

export { render, staticRenderFns }