<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left"></SideLeft>

        <div class="inmc_right">
          <div v-if="!detail">
            <!--單元內文介紹區-->
            <div class="conartical">
            
              <div class="nwstpbr">
                  <div class="nwsselbar">
                    <b-form-select
                      id="searchTag"
                      class="nwsel"
                      v-model="search.tag"
                      :options="tagOptions"
                      placeholder="全部標籤"
                      @change="GetContentSearch(search.tag, search.classic)"
                    ></b-form-select>
                    <b-form-select
                      id="searchClass"
                      class="nwsel"
                      v-model="search.classic"
                      :options="classOptions"
                      placeholder="全部類別"
                      @change="GetContentSearch(search.tag, search.classic)"
                    ></b-form-select>
                </div>

                <div class="nwtbsstx">
                  共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料
                </div>
              </div>
  
              <div class="nwstable">
                <div class="nwstitle">
                    <div class="nwstb01">發佈時間</div>
                    <div class="nwstb02">公告主題</div>
                    <div class="nwstb03">類別</div>
                </div>
                <div class="nwscbar" v-if="loading">
                  <b-spinner label="Spinning"></b-spinner>
                </div>
                <div class="nwscbar" v-else-if="List.length == 0" >
                    <div class="nwstb01"></div>
                    <div class="nwstb02">
                      目前尚無資訊
                    </div>
                    <div class="nwstb03"></div>
                </div>
                <div v-else 
                  v-for="item in List" :key="item.contentId"
                  class="nwscbar"
                >
                  <a
                    :href="'#/News/?id=' + item.contentId + (tag==null?'':'&tag='+tag)"
                    title="在原視窗打開鏈結"
                  >
                    <div class="nwstb01">{{ dateFormat(item.createDate) }}</div>
                    <div class="nwstb02">
                      {{ item.title }}
                      <span v-if="item.contentTagName.includes('重要')" class="nwtg_impt">重要</span>
                      <span v-if="item.contentTagName.includes('校外')" class="nwtg_out">校外</span>
                    </div>
                    <div class="nwstb03">{{ item.contentClassName }}</div>
                  </a>
                </div>

                <!--頁碼-->
                <div class="twpage">
                  <div class="navigation">
                    <b-pagination
                      class="pagination light-theme simple-pagination"
                      v-model="pagination.page"
                      :total-rows="pagination.rows"
                      :per-page="pagination.perPage"
                      aria-controls="my-table"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
            <!--單元內文介紹區結束-->
          </div>

          <div v-else>
            <!--單元內文介紹區-->
            <div class="conartical">
              <div class="articl">
                <div class="nwsh1">{{ newsDetail.title }}</div> 
                <div class="nwsarcd">
                    {{ dateFormat(newsDetail.createDate) }}
                    <span class="nwtg">【{{ newsDetail.contentClassName }}】</span>
                    <span v-if="newsDetail.contentTagName.includes('重要')" class="nwtg_impt">重要</span>
                    <span v-if="newsDetail.contentTagName.includes('校外')" class="nwtg_out">校外</span>
                </div>
                <div v-html="newsDetail.description" class="artcblk"></div>
              </div> 
                
              <div class="backbtn"><a :href="'#/News'+(this.tag==null?'':'?tag='+this.tag)">返回列表</a></div>
            </div>
            <!--單元內文介紹區結束-->
          </div>

        </div>

    </div>  
    <!--主內文區塊結束-->  
  
    <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
     
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/default/NavBar/Header.vue";
import SideLeft from "../../components/default/NavBar/SideLeft.vue";
import SideBar from "../../components/default/NavBar/SideBar.vue";
import Footer from "../../components/default/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      detail: false,
      loading: false,
      tag: null,
      header: {
        text1: "訊息公告",
        text2: null,
      },
      sider: {
        index: 2,
        type: 0,
        list: [{ text: "訊息公告", url: "" }],
      },
      left: {
        id: null,
        title: "訊息公告",
        icon: "mnu02.svg",
        color: "bgc_green",
        list: [],
      },
      footer: {},
      pagination: {
        perPage: 10,
        page: 1,
        rows: null,
      },
      search: {
        tag: null,
        classic: null,
      },
      List: [],
      tagOptions: [],
      classOptions: [],
      newsDetail: {
        title: "",
      },
      apiContent: (data) => this.userRequest.post("Eshntu/Content", data),
      apiContentType: (data) => this.userRequest.post("Eshntu/ContentType", data),
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    init() {
      this.tag = this.$route.query.tag;

      let id = this.$route.query.id;
      if (id == undefined) {        
        window.scrollTo(0, 0);
        
        this.search = {
          tag: this.tag??null,
          classic: null,
        }
        
        this.GetContentSearch(this.tag, null);
        
        this.getClassOption();
        this.getTagOption();
      } else {
        this.detail = true;

        let json = {
          TYPE: "HOME_DETAIL",
          content_SELECT: {
            contentId: id,
            contentTypeId: 1,
          },
        };
        this.FunctionToken(this.GetContentDetail, json);
      }
    },
    GetContentSearch(tag, classic) {

      this.detail = false;
      this.loading = true;
      this.List = [];

      let json = {
        TYPE: "HOME_LIST",
        content_SELECT: {
          pageIndex: this.pagination.page,
          pageSize: this.pagination.perPage,
          contentTypeId: 1,
          contentTagId: tag==0?null:tag,
          contentClassId: classic,
          important: tag==0?false:null,
        },
      };
      this.FunctionToken(this.GetContent, json);
    },
    GetContent(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            
            this.List = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
            this.loading = false;

            this.sider.list = [{ text: "訊息公告", url: "" }];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetContentDetail(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.newsDetail = jsonData;
            
            if(this.newsDetail.url!=null) {
              if(this.newsDetail.linkMethod == "_self") {
                window.location.href = "#"+this.newsDetail.url.replace("/#/","").replace("#/","");
              } else {
                window.open(this.newsDetail.url);
                this.$router.go(-1);
              }
            }

            this.sider.list = [
                            { text: "訊息公告", url: "#/News"+(this.tag==null?'':'?tag='+this.tag) },
                            { text: this.newsDetail.title, url: "" }
                          ];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClassOption() {
      let json = {
        TYPE: "SELECT_OPTION",
        content_type_SELECT: {
          contentClassId: 1,
        }
      };
      this.FunctionToken(this.functionGetClassOption, json);
    },
    functionGetClassOption(data) {
      this.apiContentType(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.classOptions = [];

            this.classOptions.push({ value: null, text: "全部訊息" });
            if (jsonData.length > 0) {
              jsonData.forEach((x) => {
                let obj = { value: x.key, text: x.value };
                this.classOptions.push(obj);
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTagOption() {
      let json = {
        TYPE: "SELECT_OPTION",
        content_type_SELECT: {
          contentClassId: 2,
        }
      };
      this.FunctionToken(this.functionGetTagOption, json);
    },
    functionGetTagOption(data) {
      this.apiContentType(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.tagOptions = [];

            this.tagOptions.push({ value: null, text: "全部標籤" });
            if (jsonData.length > 0) {
              jsonData.forEach((x) => {
                let obj = { value: x.key, text: x.value };
                this.tagOptions.push(obj);
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
