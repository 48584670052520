<template>
  <div class="inmc_left">
      <div :class="'mtopic ' + ((Options!=null && Options.length > 0) ? 'RWDmpnone' : '') +' '+ (List==undefined || List.length == 0?' nosbm':'')">
          <div class="mtopic_img"><img :src="'/images/police/'+left.icon" alt=""/> </div>
          <h2 :class="'mtopic_txt '+left.color">{{ left.title }}</h2>

          <div class="RWD_mtpsel" v-if="Options!=null && Options.length > 0">
              <div class="mtopic_img"><img :src="'/images/'+left.icon" alt=""/> </div>
              <b-form-select
                  v-model="rwd.type"
                  :options="Options"
                  @change="setMainOptions(rwd.type)" 
                  class="mtpsltxw"
              ></b-form-select>
          </div>
      </div>
      <div class="msubmnu RWDmpnone" v-if="List.length >0 || left.list.length > 0">
          <div class="msumbx"
              v-for="item in List" :key="item.id">
            <a :href="item.url" :class="item.key==L1?'msmac':''">{{ item.title }}</a></div> 
          <div class="msumbx"
              v-for="item in left.list" :key="item.id">
            <a :href="item.url" :class="item.key==L1?'msmac':''">{{ item.title }}</a></div> 
      </div>

      <!--RWD次選單-->
      <div class="RWD_msubmnn" v-if="leftOptions!=undefined && leftOptions.length > 0">
        <b-form-select
            v-model="L1"
            :options="leftOptions"
            @change="setLeftOptions(L1)" 
            class="msbrwsel"
        ></b-form-select>
      </div>
      <!--RWD次選單結束-->
  </div> 
</template>

<script>
export default {
  data() {
    return {
      List: [],
      Options: [],
      leftList: [],
      leftOptions: [],
      rwd: {
        type: 0,
      },
      apiBusiness: (data) => this.userRequest.post("Eshntu/Business", data),
    };
  },
  props: ["left", "main", "type", "L1"],
  mounted() {
    this.init();
  },
  watch: {
    "type"() {
      this.init();
    },
    "L2"() {
      this.init();
    }
  },
  methods: {
    init() {
      this.rwd.type = this.type;

      this.leftOptions = [];
      this.List = [];
      let jsonLeft = {
        TYPE: "HOME_ONE_LEFT",
        business_SELECT: {
          businessTypeId: this.main,
          businessOneId: this.type,
        },
      };
      this.FunctionToken(this.GetContent, jsonLeft);
    },
    GetContent(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              jsonData.forEach((x) => {
                let obj = { key: x.key, title: x.value, url: "#/Police/"+this.left.url+"?type="+this.type+"&L1="+x.key+"&id="+x.key }
                this.List.push(obj);

                let obj2 = { value: x.key, text: x.value };
                this.leftOptions.push(obj2);
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setMainOptions(type) {
        this._go("?type="+type);
    },
    setLeftOptions(key) {
        this._go("?type="+this.type+"&L1="+key+"&id="+key);
    },
  },
};
</script>
