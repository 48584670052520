<template>

  <div>
     <Header :header="header"></Header>
     <SideBar :sider="sider"></SideBar>
     <!--主內文區塊-->
     <div class="inmcont">
          <SideLeft :left="left"></SideLeft>

          <div class="inmc_right">

              <!--單元內文介紹區-->
              <div class="conartical">
                  <div class="conal">
                      <div class="articl">
                          <div class="faqall">

                            <div v-for="(question, index) in List" :key="question.title">
                                <div class="card">
                                  <Collapse :selected="List[index].isExpanded">
                                    <div slot="collapse-header">
                                      <div class="qutitle">
                                        <a>
                                          <div class="qunum">Q{{ index + 1 }}</div>
                                          <div class="quwwd">{{ question.title }}</div>
                                        </a>
                                      </div>
                                    </div>
                                    <div slot="collapse-body" class="collapse">
                                      <div class="card-body" v-html="question.answer"></div>
                                    </div>
                                  </Collapse>
                              </div>
                            </div>

                          </div>
                      </div>
                    
                  </div>
              </div>
              <!--單元內文介紹區結束-->
          </div>
      </div>
       <!--主內文區塊結束--> 

      <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Collapse from 'vue-collapse'
import Header from "../../../components/default/Police/NavBar/Header.vue";
import SideLeft from "../../../components/default/Police/NavBar/SideLeft.vue";
import SideBar from "../../../components/default/Police/NavBar/SideBar.vue";
import Footer from "../../../components/default/Police/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
    Collapse,
  },
  data() {
    return {
      loading: false,
      header: {
        text1: "駐警隊",
        text2: "常見問題",
      },
      sider: {
        index: 6,
        type: 0,
        list: [{ text: "常見問題", url: "" }],
      },
      left: {
        title: "常見問題",
        icon: "conmpc06.png",
        color: "txt_green",
        list: [],
      },
      footer: {},
      pagination: {
        perPage: 30,
        page: 1,
        rows: 5,
      },
      List: [],
      apiContent: (data) => this.userRequest.post("Eshntu/Content", data),
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    init() {     
      window.scrollTo(0, 0);
      this.loading = true;
      this.List = [];

      let json = {
        TYPE: "HOME_FAQ_LIST",
        content_SELECT: {
          pageIndex: this.pagination.page,
          pageSize: this.pagination.perPage,
          contentTypeId: 3,
        },
      };
      this.FunctionToken(this.GetContent, json);
    },
    handleAccordion(s) {
      this.List.forEach((_, i) => {
        this.List[i].isExpanded = i === s ? !this.List[i].isExpanded : false
      })
    },
    GetContent(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            jsonData.outData.forEach((x) => {
              let obj = { 
                title: x.title,
                answer: x.description,
                isExpanded: false,
              }
              this.List.push(obj);
            });
            this.loading = false;
            console.log(this.List);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
