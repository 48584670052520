<template>
  <div>
    
    <!--fatfooter區域-->
    <div class="fatfooter">
        <div class="illpc02"><img src="/images/police/inbmill.png" alt=""></div>
        <div class="fatfcon">
            <div class="fatbkal">
                <div class="fatbck">
                    <div class="fatfh1">消息與資源</div>
                    <div class="fatfh2al">
                        <div class="fatfh2"><a href="#/Police/News">最新消息</a></div> 
                        <div class="fatfh2"><a href="#/Police/Lose">失物招領</a></div> 
                        <div class="fatfh2"><a href="#/Police/FAQ">常見問題</a></div> 
                        <div class="fatfh2"><a href="#/Police/Download">下載專區</a></div> 
                    </div> 
                </div>

                <div class="fatbck">
                    <div class="fatfh1"><a href="#/Police/Content?type=14">關於本隊</a></div>
                    <div class="fatfh2al">
                        <div class="fatfh2"
                          v-for="item in List_6_14" 
                            :key="item.businessId">
                          <a :href="'#/Police/Content?type='+'14'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></div> 
                    </div>
                </div>

                <div class="fatbck">
                    <div class="fatfh1"><a href="#/Police/Content?type=15">服務項目</a></div>
                    <div class="fatfh2al">                        
                    <div class="fatfh2"
                          v-for="item in List_7_15" 
                            :key="item.businessId">
                          <a :href="'#/Police/Content?type='+'15'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></div> 
                    </div>
                </div>
                
                <div class="fatbck">
                    <div class="fatfh1"><a href="#/Police/Content?type=16">常用連結</a></div>
                    <div class="fatfh2al">                       
                    <div class="fatfh2"
                          v-for="item in List_8_16" 
                            :key="item.businessId">
                          <a :href="'#/Police/Content?type='+'16'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></div> 
                    </div>
                </div>
            
            </div>
            <div class="fatinf">
                環安衛中心駐衛警察隊電話：02-3366-2185-6 / 傳真：02-2369-6474
                <p>24小時緊急報案專線電話：02-3366-9110</p>
                <p>電子信箱：police@ntu.edu.tw</p>
                <p>地址：10617 臺北市大安區羅斯福路四段一號 展書樓1樓<a href="https://maps.app.goo.gl/7xjP5Qr62BuRayoR8" class="mapico" target="_blank" title="環安衛中心駐衛警察隊所在位置,開啟GOOGLE地圖(另開新視窗)"><img src="/images/police/mapico.png" align="absmiddle" alt=""/></a></p>
            </div>           
            
        </div>    
    </div>
    <!--fatfooter區域結束-->
    
    <div class="footer">
        <div class="fotcon">
            <div class="fotc_left">
                <div class="ftntulog"><img src="/images/police/ntulogo.svg" alt=""/></div>
                <div class="ftmlog"><img src="/images/police/foter_title.svg" alt=""/></div>
            </div>
            <div class="fotc_right">
                ©2024 臺灣大學環安衛中心 版權所有<br>
                總瀏覽人數：{{ totalCount }} <span v-if="!footer.index"> / 本頁瀏覽人數：{{ pageCount }}</span> &nbsp; &nbsp;&nbsp;更新日期：{{ dateFormatFloat(updateDate) }} <br>
                最佳解析度 1920*1080  建議瀏覽器：Chrome、Edge 
            </div>
        </div>
    </div>
  </div>
</template>

<script>
var date = new Date();
var dateFormat =
  date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
export default {
  data() {
    return {
      totalCount: 1,
      pageCount: 1,
      updateDate: dateFormat,
      pageUrl: null,
      List_6_14: [],
      List_7_15: [],
      List_8_16: [],
      apiCalculateView: (data) =>
        this.userRequest.post("Eshntu/CalculateView", data),
      apiBrowseLog: (data) =>
        this.userRequest.post("Eshntu/BrowseLog", data),
      apiBusiness: (data) => this.userRequest.post("Eshntu/Business", data),
    };
  },
  mounted() {
    this.init();
  },
  props: ["footer"],
  watch: {},
  methods: {
    init() {
      this.pageUrl = this.$route.path;
      const classiePlugin = document.createElement("script");
      classiePlugin.setAttribute(
      "src",
      "/js/classie.js"
      );
      classiePlugin.async = true;
      document.head.appendChild(classiePlugin);

      const demoPlugin = document.createElement("script");
      demoPlugin.setAttribute(
      "src",
      "/js/demo1.js"
      );
      demoPlugin.async = true;
      document.head.appendChild(demoPlugin);  

      let jsonPerson = {
        TYPE: "HOME_UPDATE_COUNT",
        calculate_view_SELECT: {
          type: 2,
          page: this.pageUrl,
        },
      };
      this.FunctionToken(this.UpdateCount, jsonPerson);
      
      let jsonDate = {
        TYPE: "HOME_SELECT",
      };
      this.FunctionToken(this.GetUpdateDate, jsonDate); 
      
      this.getContentList_6_14();
      this.getContentList_7_15();
      this.getContentList_8_16();

    },
    GetCount(data) {
      this.apiCalculateView(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.totalCount = jsonData.totalCount;
            this.pageCount = jsonData.outData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetUpdateDate(data) {
      this.apiBrowseLog(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.updateDate = json.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdateCount(data) {
      this.apiCalculateView(data)
        .then((res) => {
          let jsonCount = {
            TYPE: "HOME_SELECT",
            calculate_view_SELECT: {
              type: 2,
              page: this.pageUrl,
            },
          };
          this.FunctionToken(this.GetCount, jsonCount);  
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContentList_6_14() {
      let json = {
        TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 6,
            businessOneId: 14,
          },
      };
      this.FunctionToken(this.functionContentList_6_14, json);
    },
    functionContentList_6_14(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_6_14 = jsonData;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContentList_7_15() {
      let json = {
        TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 7,
            businessOneId: 15,
          },
      };
      this.FunctionToken(this.functionContentList_7_15, json);
    },
    functionContentList_7_15(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_7_15 = jsonData;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContentList_8_16() {
      let json = {
        TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 8,
            businessOneId: 16,
          },
      };
      this.FunctionToken(this.functionContentList_8_16, json);
    },
    functionContentList_8_16(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_8_16 = jsonData;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
