<template>
  <div class="mconarall">
    <Header :header="header"></Header>
    <div class="infall">
      <Account v-if="typeSelect == 1"></Account>
      <Group v-if="typeSelect == 5"></Group>
      <Log v-if="typeSelect == 7"></Log>
      <Information v-if="typeSelect == 9"></Information>
      <Public v-if="typeSelect == 10"></Public>
    </div>
  </div>
</template>

<script>
import Header from "../../components/admin/NavBar/Header.vue";
import Account from "../../components/admin/Account/Account.vue";
import Group from "../../components/admin/Account/Group.vue";
import Log from "../../components/admin/Account/Log.vue";
import Information from "../../components/admin/Account/Information.vue";
import Public from "../../components/admin/Account/Public.vue";
export default {
  components: {
    Header,
    Account,
    Group,
    Log,
    Information,
    Public,
  },
  data() {
    return {
      header: {
        text1: '帳號管理',
        text2: '使用者維護'
      },
      typeSelect: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.query'() {
      this.init()
    }
  },
  methods: {
    init() {
      let type = this.$route.query.type;
      if (type == undefined) {
        type = 1
      }
      this.typeSelect = parseInt(type);
      this.setActive();
    },
    setActive() {
      switch (this.typeSelect) {
        case 1:
          this.header.text1 = '帳號管理';
          this.header.text2 = '使用者維護';
          break;
        case 5:
          this.header.text1 = '帳號管理';
          this.header.text2 = '群組維護';
          break;
        case 7:
          this.header.text1 = '權限管理';
          this.header.text2 = '日誌檢視';
          break;
        case 9:
          this.header.text1 = '個人資訊';
          this.header.text2 = '';
          break;
        case 10:
          this.header.text1 = '公用帳號';
          this.header.text2 = '';
          break;
      }
    },
  }
};
</script>