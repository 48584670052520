<template>
  <div>
    <b-modal v-model="deleteModal.show" title="刪除警告" ok-only>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click="functionDelete"
        >
          確定
        </button>
      </template>
      <p class="my-4">請問確定要刪除此筆資料嗎?</p>
      <!--p class="my-4">key:{{ deleteModal.id }}</p>
      <p class="my-4">type:{{ deleteModal.type }}</p-->
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apiUserInfo: (data) =>
        this.userRequest.post("Eshntu/UserInfo", data),
      apiAccountGroup: (data) =>
        this.userRequest.post("Eshntu/AccountGroup", data),
      apiContent: (data) => 
        this.userRequest.post("Eshntu/Content", data),
      apiBusiness: (data) => 
        this.userRequest.post("Eshntu/Business", data),
      apiPrivateContent: (data) => 
        this.userRequest.post("Eshntu/PrivateContent", data),
      apiDownload: (data) => 
        this.userRequest.post("Eshntu/Download", data),
    };
  },
  props: ["deleteModal"],
  methods: {
    toastShow() {
      this.$bvToast.toast("刪除成功", {
        title: "提示",
        solid: true,
      });
      this.$emit("init");
    },
    functionDelete() {
      if (this.deleteModal.type == "UserInfo") {
        let json = {
          TYPE: "DELETE",
          User_Info_DELETE: {
            userId: this.deleteModal.id,
          },
        };
        this.FunctionToken(this.UserInfoDelete, json);
      } else if (this.deleteModal.type == "AccountGroup") {
        let json = {
          TYPE: "DELETE",
          ACCOUNT_GROUP_DELETE: {
            AG_ID: this.deleteModal.id,
          },
        };
        this.FunctionToken(this.AccountGroupDelete, json);
      } else if (this.deleteModal.type == "Content") {
        let json = {
          TYPE: "DELETE",
          content_DELETE: {
            contentId: this.deleteModal.id,
            contentTypeId: 1,
          },
        };
        this.FunctionToken(this.ContentDelete, json);
      } else if (this.deleteModal.type == "ContentPolice") {
        let json = {
          TYPE: "DELETE",
          content_DELETE: {
            contentId: this.deleteModal.id,
            contentTypeId: 2,
          },
        };
        this.FunctionToken(this.ContentDelete, json);
      } else if (this.deleteModal.type == "ContentFAQ") {
        let json = {
          TYPE: "DELETE",
          content_DELETE: {
            contentId: this.deleteModal.id,
            contentTypeId: 3,
          },
        };
        this.FunctionToken(this.ContentDelete, json);
      } else if (this.deleteModal.type == "Business") {
        let json = {
          TYPE: "DELETE",
          business_DELETE: {
            businessId: this.deleteModal.id,
            businessTypeId: this.deleteModal.typeId,
          },
        };
        this.FunctionToken(this.BusinessDelete, json);
      } else if (this.deleteModal.type == "PrivateContent") {
        let json = {
          TYPE: "DELETE",
          private_content_DELETE: {
            contentId: this.deleteModal.id,
          },
        };
        this.FunctionToken(this.PrivateContentDelete, json);
      } else if (this.deleteModal.type == "Download") {
        let json = {
          TYPE: "DELETE",
          download_DELETE: {
            downloadId: this.deleteModal.id,
          },
        };
        this.FunctionToken(this.DownloadDelete, json);
      }
      this.deleteModal.show = false;
    },
    //api
    UserInfoDelete(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          //console.log(json)
          if (json.Status) {
            this.toastShow();
          } else {
            alert(json.Data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AccountGroupDelete(data) {
      this.apiAccountGroup(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          //console.log(json)
          if (json.Status) {
            this.toastShow();
          } else {
            alert(json.Data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ContentDelete(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.toastShow();
          } else {
            alert(json.Message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    BusinessDelete(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.toastShow();
          } else {
            alert(JSON.parse(json.Data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    PrivateContentDelete(data) {
      this.apiPrivateContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.toastShow();
          } else {
            alert(json.Message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    DownloadDelete(data) {
      this.apiDownload(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.toastShow();
          } else {
            alert(json.Message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>