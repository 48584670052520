<template>
  <div>
    <!--fatfooter區域-->
    <!--<div class="fatfooter">
        <div class="fatfcon">
            <div class="fatbck">
                <div class="fatfh1">主要業務</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">教育訓練</a></div> 
                    <div class="fatfh2"><a href="#">健康管理</a></div> 
                    <div class="fatfh2"><a href="#">保護計畫</a></div> 
                    <div class="fatfh2"><a href="#">實驗場所</a></div> 
                    <div class="fatfh2"><a href="#">廢棄物清運</a></div> 
                </div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">教育訓練</a></div> 
                    <div class="fatfh2"><a href="#">健康管理</a></div> 
                    <div class="fatfh2"><a href="#">保護計畫</a></div> 
                    <div class="fatfh2"><a href="#">實驗場所</a></div> 
                    <div class="fatfh2"><a href="#">廢棄物清運</a></div> 
                </div>
            </div>
            
            <div class="fatbck">
                <div class="fatfh1">訊息公告</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">訊息公告</a></div> 
                </div>
            </div>
            
            <div class="fatbck">
                <div class="fatfh1">中心簡介</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">關於本中心</a></div> 
                    <div class="fatfh2"><a href="#">我們的工作群</a></div> 
                    <div class="fatfh2"><a href="#">業務職掌</a></div> 
                    <div class="fatfh2"><a href="#">中心位置</a></div> 
                    <div class="fatfh2"><a href="#">場地租借</a></div> 
                    <div class="fatfh2"><a href="#">數位電表</a></div> 
                </div>
            </div>
            
            <div class="fatbck">
                <div class="fatfh1">法規輯要</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">本校環安衛相關</a></div> 
                    <div class="fatfh2"><a href="#">職業安全衛生</a></div> 
                    <div class="fatfh2"><a href="#">化學物質</a></div> 
                    <div class="fatfh2"><a href="#">輻射防護</a></div> 
                    <div class="fatfh2"><a href="#">生物安全</a></div> 
                    <div class="fatfh2"><a href="#">廢棄物</a></div> 
                    <div class="fatfh2"><a href="#">飲用水管理</a></div> 
                </div>
            </div>
            
            <div class="fatbck">
                <div class="fatfh1">文件下載</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">環安衛行事曆</a></div> 
                    <div class="fatfh2"><a href="#">申請表格</a></div> 
                    <div class="fatfh2"><a href="#">其它文件</a></div> 
                    <div class="fatfh2"><a href="#">年報</a></div> 
                </div>
            </div>
        </div>    
    </div>-->
    <!--fatfooter區域結束-->
    
    <div class="footer">
        <div class="fotcon">
            <div class="fotc_left">
                <div class="ftntulog"><img src="/images/ntulogo.svg" alt=""/></div>
                <div class="ftmlog"><img src="/images/footerLog.svg" alt=""/></div>
            </div>
            <div class="fotc_right">
                Copyright 2024. All Rights Reserved. 版權所有翻印必究；請勿拷貝或轉載於任何媒體<br>
                106038 臺北市大安區芳蘭路71號 / (02)3366-2003~6 / Fax：(02)3366-9987<br>
                綜合單一服務窗口：(02)3366-9422 /  E-mail： epc@ntu.edu.tw<br>
                總瀏覽人數：{{ totalCount }} <span v-if="!footer.index"> / 本頁瀏覽人數：{{ pageCount }}</span> &nbsp; &nbsp;&nbsp;更新日期：{{ dateFormatFloat(updateDate) }} <br>
                最佳解析度 1920*1080  建議瀏覽器：Chrome、Edge 
            </div>
        </div>
        <div class="illpc02"><img src="/images/illpc02.png" alt=""/></div>
    </div>
    <div class="topbar">
        <div class="tpbrbox06"></div>
        <div class="tpbrbox05"></div>
        <div class="tpbrbox04"></div>
        <div class="tpbrbox03"></div>
        <div class="tpbrbox02"></div>
        <div class="tpbrbox01"></div>      
    </div>
  </div>
</template>

<script>
var date = new Date();
var dateFormat =
  date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
export default {
  data() {
    return {
      totalCount: 1,
      pageCount: 1,
      updateDate: dateFormat,
      pageUrl: null,
      apiCalculateView: (data) =>
        this.userRequest.post("Eshntu/CalculateView", data),
      apiBrowseLog: (data) =>
        this.userRequest.post("Eshntu/BrowseLog", data),
    };
  },
  props: ["footer"],
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    init() {
      this.pageUrl = this.$route.path;
      const classiePlugin = document.createElement("script");
      classiePlugin.setAttribute(
      "src",
      "/js/classie.js"
      );
      classiePlugin.async = true;
      document.head.appendChild(classiePlugin);

      const demoPlugin = document.createElement("script");
      demoPlugin.setAttribute(
      "src",
      "/js/demo1.js"
      );
      demoPlugin.async = true;
      document.head.appendChild(demoPlugin);  

      let jsonPerson = {
        TYPE: "HOME_UPDATE_COUNT",
        calculate_view_SELECT: {
          type: 1,
          page: this.pageUrl,
        },
      };
      this.FunctionToken(this.UpdateCount, jsonPerson);

      let jsonDate = {
        TYPE: "HOME_SELECT",
      };
      this.FunctionToken(this.GetUpdateDate, jsonDate); 
 
    },
    GetCount(data) {
      this.apiCalculateView(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.totalCount = jsonData.totalCount;
            this.pageCount = jsonData.outData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetUpdateDate(data) {
      this.apiBrowseLog(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.updateDate = json.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdateCount(data) {
      this.apiCalculateView(data)
        .then((res) => {
          let jsonCount = {
            TYPE: "HOME_SELECT",
            calculate_view_SELECT: {
              type: 1,
              page: this.pageUrl,
            },
          };
          this.FunctionToken(this.GetCount, jsonCount);  
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
