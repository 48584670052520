<template>
  <div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
    this.init();
  },
  watch: {
  },
  methods: {
    init() {
      this._go("Account/?type=9");
    },
  }
};
</script>