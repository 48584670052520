<template>
  <div>
    <DeleteModal :deleteModal="deleteModal" @init="init(false)"></DeleteModal>
    <div v-if="isPermission==false">資料讀取中</div>
    <div v-else-if="permission.isView">
      <div v-if="!detail">
        <b-row
          ><b-col>
            <b-form-input
              v-model="search.userName"
              placeholder="請輸入姓名"
            ></b-form-input
          ></b-col>
          <b-col>
            <b-form-input
              v-model="search.userAccount"
              placeholder="請輸入使用者帳號"
            ></b-form-input
          ></b-col>
          <b-col>
          <b-form-select
              v-model="search.AG_ID"
              :options="groupOptions"
              placeholder="請輸入所屬群組"
            ></b-form-select></b-col>
          <b-col>
            <b-form-select
              v-model="search.statusSelected"
              :options="statusOption"
            ></b-form-select></b-col
          ><b-col cols="1"
            ><b-button @click="FunctionSearch"> <b-icon icon="search"></b-icon></b-button
          ></b-col>
          <b-col cols="2"
            ><b-button
              @click="_go('?type=1&id=null')"
              v-if="permission.isInsert"
              >新增</b-button
            ></b-col
          ></b-row
        >
        <b-container>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>使用者帳號</b-th>
                <b-th>姓名</b-th>
                <b-th>帳號狀態</b-th>
                <b-th>建檔日期</b-th>
                <b-th v-if="permission.isDelete || permission.isView"
                  >修改</b-th
                >
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="item in accountList" :key="item.userId">
                <b-td>{{ item.userAccount }}</b-td>
                <b-td>{{ item.userName }}</b-td>
                <b-td>{{ item.openStatusName }}</b-td>
                <b-td>{{ dateFormat(item.createDate) }}</b-td>
                <b-td v-if="permission.isDelete || permission.isView">
                  <b-button
                    class="mr-5"
                    variant="info"
                    @click="_go('?type=1&id=' + item.userId)"
                    >{{ (permission.isUpdate || permission.isInsert)  ? "編輯" : "檢視" }}</b-button
                  >
                  <a
                    href="javascript:"
                    v-if="permission.isDelete"
                    @click="FunctionDelete(item)"
                    ><img src="/images/backend/del_icon.png" alt="刪除icon"
                  /></a>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="page mt-2">
            <span class="pagination-total">共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料</span>
            <b-pagination
              class="pagination light-theme simple-pagination"
              v-model="pagination.page"
              :total-rows="pagination.rows"
              :per-page="pagination.perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-container>
      </div>
      <div v-else>
        <div class="btare">
          <div class="backrul">
            <a href="#/backend/Account/?type=1">返回查詢結果</a>
          </div>
        </div>
        <b-row>
          <b-col>
            <label>使用者帳號*</label>
            <b-form-input
              v-model="accountDetail.userAccount"
              placeholder="請輸入使用者帳號"
              maxlength="16"
            ></b-form-input>
          </b-col>
          <b-col>
            <label>使用者姓名*</label>
            <b-form-input
              v-model="accountDetail.userName"
              placeholder="請輸入使用者姓名"
              maxlength="16"
            ></b-form-input>
          </b-col>
          <b-col>
            <label>所屬群組*</label>
            <b-form-select
              v-model="accountDetail.AG_ID"
              :options="groupOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row v-if="$route.query.id == 'null'">
          <b-col>
            <label>使用者密碼*</label>
            <div class="card-header h5 px-3 py-2 " style="font-size:0.8em">
              密碼長度6個字元~12個字，以英文大小寫、數字等限定，需至少一位大寫、一位小寫、一位數字</div>
            <b-form-input
              type="password"
              v-model="accountDetail.Pawd"
              placeholder="請輸入使用者密碼"
              maxlength="12"
            ></b-form-input>
          </b-col>
          <b-col>
            <label>確認密碼*</label>
            <div class="card-header h5 px-3 py-2 " style="font-size:0.8em">
              密碼長度6個字元~12個字，以英文大小寫、數字等限定，需至少一位大寫、一位小寫、一位數字</div>
            <b-form-input
              type="password"
              v-model="accountDetail.checkPawd"
              placeholder="請輸入使用者密碼"
              maxlength="12"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>電子郵件*</label>
            <b-form-input
              v-model="accountDetail.email"
              placeholder="請輸入電子郵件"
              maxlength="100"
            ></b-form-input>
          </b-col>
          <b-col>
            <label></label>
            <b-form-checkbox 
              v-model="accountDetail.openStatus">帳號啟用
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row
          ><b-col>
            <label>建檔人員</label>
            <b-form-input
              disabled="disabled"
              v-model="accountDetail.creatorName"
              class="mb-2"
            ></b-form-input></b-col
          ><b-col>
            <label>建檔日期</label>
            <b-form-datepicker
              disabled="disabled"
              v-model="accountDetail.createDate"
              class="mb-2"
            ></b-form-datepicker></b-col
        ></b-row>
        <b-row
          ><b-col>
            <label>異動人員</label>
            <b-form-input
              disabled="disabled"
              v-model="accountDetail.modifierName"
              class="mb-2"
            ></b-form-input></b-col
          ><b-col>
            <label>異動日期</label>
            <b-form-datepicker
              disabled="disabled"
              v-model="accountDetail.modifyDate"
              class="mb-2"
            ></b-form-datepicker></b-col
        ></b-row>
        <div v-if="permission.isInsert || permission.isUpdate">
          <b-button @click="save">儲存</b-button>
          <b-button @click="unLockKey" v-if="id!='null'">解除鎖定</b-button>
        </div>
      </div>
    </div>
    <div v-else>無權限</div>
  </div>
</template>

<script>
import DeleteModal from "../modal/deleteModal.vue";
export default {
  components: {
    DeleteModal,
  },
  data() {
    return {
      detail: false,
      initSeach: false,
      permissionAllStatus: false,
      permissionShow: false,
      accountList: [],
      search: { statusSelected: 1, AG_ID: null },
      statusOption: [
        { value: null, text: "全部" },
        { value: 0, text: "停用" },
        { value: 1, text: "啟用" },
      ],
      groupOptions: [],
      pagination: {
        perPage: 8,
        page: 1,
        rows: 5,
      },
      accountDetail: {},
      deleteModal: {
        id: null,
        show: false,
        type: "UserInfo",
      },
      apiUserInfo: (data) => this.userRequest.post("Eshntu/UserInfo", data),
    };
  },
  mounted() {
    this.init(true);
  },
  watch: {
    "$route.query"() {
      this.init(false);
    },
    "pagination.page"() {
      this.init(false);
    },
  },
  methods: {
    init(Init) {
      this.getPermission("PJ202409010003");
      this.id = this.$route.query.id;
      if (this.id == undefined) {
        this.detail = false;
        this.FunctionSearch();   

        if(Init) {        
          this.initSeach = true;
          this.initGroup = true;
          this.getGroupOption();
        }
      } else if (this.id == "null") {
        this.detail = true;
        this.initGroup = true;
        this.accountDetail = {
          AG_ID: "",
          creatorName: sessionStorage.getItem("LoginName"),
          modifierName: sessionStorage.getItem("LoginName"),
          createDate: new Date(),
          modifyDate: new Date(),
        };
        this.getGroupOption();
      } else {
        this.detail = true;
        this.accountDetail = {};
        let json = {
          TYPE: "SELECT",
          user_info_SELECT: {
            userId: this.id,
          },
        };
        this.FunctionToken(this.GetAccountDetail, json);
        this.getGroupOption();
      }
    },
    FunctionSearch(){
      let json = {
        TYPE: "LIST",
        user_info_SELECT: {
          userName: this.search.userName,
          userAccount: this.search.userAccount,
          AG_ID: this.search.AG_ID,
          openStatus: this.search.statusSelected,
          pageIndex: this.pagination.page,
          pageSize: this.pagination.perPage,
        },
      };
      this.FunctionToken(this.GetAccount, json);
    },
    FunctionDelete(item) {
      this.deleteModal.show = true;
      this.deleteModal.id = item.userId;
      this.deleteModal.title = item.userAccount;
    },
    save() {
      if (this.id == "null") {
        let json = {
          TYPE: "INSERT",
          user_info_INSERT: {
            userName: this.accountDetail.userName,
            userAccount: this.accountDetail.userAccount,
            password: this.accountDetail.Pawd,
            checkPassword: this.accountDetail.checkPawd,
            email: this.accountDetail.email,
            openStatus: this.accountDetail.openStatus,
            AG_ID: this.accountDetail.AG_ID,
          },
        };
        //console.log(json);
        this.FunctionToken(this.InsertAccount, json);
      } else {
        let json = {
          TYPE: "UPDATE",
          user_info_UPDATE: {
            userId: this.accountDetail.userId,
            userName: this.accountDetail.userName,
            userAccount: this.accountDetail.userAccount,
            password: this.accountDetail.Pawd,
            checkPassword: this.accountDetail.checkPawd,
            email: this.accountDetail.email,
            openStatus: this.accountDetail.openStatus,
            AG_ID: this.accountDetail.AG_ID,
          },
        };
        //console.log(json);
        this.FunctionToken(this.UpdateAccount, json);
      }
    },
    InsertAccount(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          //console.log(json);

          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            //console.log(jsonData);
            this.$bvToast.toast("新增成功", {
              title: "提示",
              solid: true,
            });
            this.pagination.page = 1;
            this._go("?type=1");
          } else {
            alert(this.Str_Replace(JSON.parse(json.Data), ",", "\r\n"));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdateAccount(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          //console.log(json);

          if (json.Status) {
            this.$bvToast.toast("更新成功", {
              title: "提示",
              solid: true,
            });
            this._go("?type=1");
          } else {
            alert(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    unLockKey(){
      let json = {
        TYPE: "UNLOCK",
        User_Info_UPDATE: { 
          userAccount: this.accountDetail.userAccount,
        },
      };
      this.FunctionToken(this.GetUnLockKey, json);
    },
    getGroupOption() {
      let json = {
        TYPE: "GROUP_OPTION",
      };
      this.FunctionToken(this.functionGetGroupOption, json);
    },
    functionGetGroupOption(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.groupOptions = [];

            if(this.initSeach) {
              this.groupOptions.push({ value: "", text: "請輸入所屬群組" });
            }
            // //console.log(jsonData)
            if (jsonData.length > 0) {
              jsonData.forEach((x) => {
                let obj = { value: x.key, text: x.value };
                this.groupOptions.push(obj);
              });
              if (this.initSeach && this.initGroup) {
                this.search.AG_ID = this.groupOptions[0].value;
              }
              else if (this.initGroup) {
                this.accountDetail.AG_ID = this.groupOptions[0].value;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetAccount(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            //console.log(jsonData);
            this.accountList = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetAccountDetail(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.accountDetail = jsonData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetUnLockKey(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          //console.log(json);

          if (json.Status) {
            this.$bvToast.toast("帳號已解除", {
              title: "提示",
              solid: true,
            });
            this._go("?type=1");
          } else {
            alert(JSON.parse(json.Data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
