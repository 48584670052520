<template>
  <div class="mconarall">
    <Header :header="header"></Header>
    <div class="infall">
      <News
        v-if="typeSelect == 1 || typeSelect == 2 || typeSelect == 4"
        :contentId="contentId"
        :typeSelect="typeSelect"></News>
    </div>
  </div>
</template>

<script>
import Header from "../../components/admin/NavBar/Header.vue";
import News from "../../components/admin/Content/Content.vue";
export default {
  components: {
    Header,
    News,
  },
  data() {
    return {
      header: {
        text1: '環安衛中心',
        text2: '訊息公告維護'
      },
      typeSelect: null,
      contentId: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.query'() {
      this.init()
    }
  },
  methods: {
    init() {
      this.contentId = this.$route.query.id;
      let type = this.$route.query.type;
      if (type == undefined) {
        type = 0
      }
      this.typeSelect = parseInt(type);
      this.setActive();
    },
    setActive() {
      switch (this.typeSelect) {
        case 1:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '訊息公告維護';
          break;
        case 2:
          this.header.text1 = '駐警隊';
          this.header.text2 = '最新消息維護';
          break;
        case 4:
          this.header.text1 = '環安衛中心-英文版';
          this.header.text2 = '訊息公告維護';
          break;
      }
    },
  }
};
</script>