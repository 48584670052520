<template>
  <div></div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      apiBackendLogin: (data) =>
        this.userRequest.post("Eshntu/BackendLogin", data),
    }
  },
  mounted() {
    this.init();
  },
  watch: {
  },
  methods: {
    init() {
      let json = {
        TYPE: "LOGOUT",
        login_SELECT: {},
      }
      this.FunctionToken(this.functionLogOut, json);
    },
    functionLogOut(data) {
      this.apiBackendLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            //console.log(json)
            this._go("/ISY")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>