<template>
  <div>
    <div class="intopm">
        <div class="intpmal">
            <div class="intp_left">
                <div class="intntulogo"><img src="/images/police/ntulogo.svg" alt=""/></div>
                <h1 class="intslogo"><a href="#/Police"><img src="/images/police/inslogo.svg" alt=""/></a></h1>
            </div>
            <div class="intp_right">
                <div class="tpqlnk">
                    <span><a href="/">環安衛中心</a></span>｜<span><a href="https://www.ntu.edu.tw/" target="_blank" title="臺大官網(另開新視窗)">臺大首頁</a></span>
                </div>

                <nav class="menu">
                    <ul>
                      <li :class="'menu01'+(sider.index==1?' mnac ':'') "><a href="#/Police/News">最新消息</a></li>
                      <li :class="'menu02'+(sider.index==2?' mnac ':'') "><a href="#/Police/Lose">失物招領</a></li>
                      <li :class="'menu03'+(sider.index==3?' mnac ':'') "><a href="#/Police/Content?type=14">關於本隊</a></li>
                      <li :class="'menu04'+(sider.index==4?' mnac ':'') "><a href="#/Police/Content?type=15">服務項目</a></li>
                      <li :class="'menu05'+(sider.index==5?' mnac ':'') "><a href="#/Police/Content?type=16">常用連結</a></li>
                      <li :class="'menu06'+(sider.index==6?' mnac ':'') "><a href="#/Police/FAQ">常見問題</a></li>
                      <li :class="'menu07'+(sider.index==7?' mnac ':'') "><a href="#/Police/Download">下載專區</a></li>
                    </ul>
                </nav>
                
                <!--========== 手機選單 ==========-->
                <div class="tright">
                    <div id="trigger-overlay" class="in-burger"><span></span></div>
                </div>

                <div class="overlay overlay-hugeinc RWD_mnuopen">
                    <button type="button" class="overlay-close">Close</button>
                <nav>
                        <nav>
                            <ul> 
                                <li><a href="#/Police/News">最新消息</a></li>
                                <li><a href="#/Police/Lose">失物招領</a></li>
                                <li><a href="#/Police/Content?type=14">關於本隊</a></li>
                                <li><a href="#/Police/Content?type=15">服務項目</a></li>
                                <li><a href="#/Police/Content?type=16">常用連結</a></li>
                                <li><a href="#/Police/FAQ">常見問題</a></li>
                                <li><a href="#/Police/Download">下載專區</a></li>
                                <li><a href="#/">環安衛中心</a></li>
                            </ul>
                        </nav>
                    </nav>
                </div>
                <!--=========================-->
                
            </div>
        </div>
        
      </div>
    
      <div class="sitmap">
        <a href="#/Police">
          <img src="/images/hico.png" alt="" align="absmiddle" />首頁
        </a>
        <span v-for="(item, index) in sider.list" :key="index">
         / <a :href="item.url==''?'javascript:void(0)':item.url">{{ item.text }}</a>
        </span>
      </div>    
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
    this.init();
  },
  watch: {},
  props: ["sider"],
  methods: {
    init() {
      
    },
  },
};
</script>