<template>
  <div class="mconarall">
    <Header :header="header"></Header>
    <div class="infall">
      <Download
        :contentId="contentId"
        :typeSelect="1"></Download>
      <Schedule
        :contentId="contentId"
        :typeSelect="2"></Schedule>
    </div>
  </div>
</template>

<script>
import Header from "../../components/admin/NavBar/Header.vue";
import Download from "../../components/admin/Download/Content.vue";
import Schedule from "../../components/admin/Schedule/Content.vue";
export default {
  components: {
    Header,
    Download,
    Schedule,
  },
  data() {
    return {
      typeSelect: 0,
      header: {
        text1: '駐警隊',
        text2: '下載專區維護'
      },
      contentId: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.query'() {
      this.init()
    }
  },
  methods: {
    init() {
      this.contentId = this.$route.query.id;
      let type = this.$route.query.type;
      if (type == undefined) {
        type = 0
      }
      this.typeSelect = parseInt(type);
      this.setActive();
    },
    setActive() {
      switch (this.typeSelect) {
        case 1:
          this.header.text1 = '駐警隊';
          this.header.text2 = '下載專區維護';
          break;
        case 2:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '環安衛行事曆';
          break;
      }
    },
  }
};
</script>