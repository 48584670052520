<template>
  <div>
    <Header :header="header"></Header>
    <div class="tpview">
        <SideBar :sider="sider"></SideBar>
        <div class="mtitall">
            <h1 class="ntulogo"></h1>
            <h2 class="mtit01"></h2>
            <div class="mtit02"></div>
        </div>
        
        <div class="antm">
            <video loop muted="" poster="images/bkcnpc.png" autoplay="autoplay" playsinline="" class="mvimage" >
              <source src="images/indanm.mp4" type="video/mp4">
            </video>
        </div>
    </div>
    
    <div class="mservice">
        <div class="mserbar">
            <div class="mserbox bgc_lpink RWDnone"></div>
            <div class="mserbox bgc_pink">
                <a href="#/Content?type=1">
                    <div class="mseimg"><img src="/images/msv01.svg" alt=""/></div>
                    <div class="msetex">教育訓練</div>
                </a>
            </div>
            <div class="mserbox bgc_blue">
                <a href="#/Content?type=2">
                    <div class="mseimg"><img src="/images/msv02.svg" alt=""/></div>
                    <div class="msetex">健康管理</div>
                </a>
            </div>
            <div class="mserbox bgc_green">
                <a href="#/Content?type=3">
                    <div class="mseimg"><img src="/images/msv03.svg" alt=""/></div>
                    <div class="msetex">保護計畫</div>
                </a>
            </div>
            
            <div class="mserbox bgc_lpink msb_helf serhelv"></div>
            
            <div class="mserbox bgc_org">
                <a href="#/Content?type=4">
                    <div class="mseimg"><img src="/images/msv04.svg" alt=""/></div>
                    <div class="msetex">實驗場所</div>
                </a>
            </div>
            <div class="mserbox bgc_blue">
                <a href="#/Content?type=5">
                    <div class="mseimg"><img src="/images/msv05.svg" alt=""/></div>
                    <div class="msetex">廢棄物清運</div>
                </a>
            </div>
            
            <div class="mserbox bgc_lblue msb_helf serhelv"></div>
            
            <div class="mserbox bgc_green">
                <a href="#/Content?type=6">
                    <div class="mseimg"><img src="/images/msv06.svg" alt=""/></div>
                    <div class="msetex">災害防救</div>
                </a>
            </div>
            <div class="mserbox bgc_lblue RWDnone"></div>
            <div class="mserbox msb_helf bgc_lgreen RWDnone"></div>
            <div class="mserbox bgc_blue">
                <a href="#/Police" target="_blank" title="駐警隊(另開新視窗)">
                    <div class="mseimg"><img src="/images/msv97.svg" alt=""/></div>
                    <div class="msetex">駐警隊</div>
                </a>
            </div>
            <div class="mserbox bgc_green">
                <a href="#/Content?type=6&L1=69&L2=71&id=71">
                    <div class="mseimg"><img src="/images/msv98.svg" alt=""/></div>
                    <div class="msetex">AED</div>
                </a>
            </div>
            
            <div class="mserbox bgc_lgreen msb_helf serhelv"></div>
            
            <div class="mserbox bgc_org">
                <a href="https://eposhuser.ntu.edu.tw/Front/login.aspx" target="_blank" title="運作管理系統(另開新視窗)">
                    <div class="mseimg"><img src="/images/msv99.svg" alt=""/></div>
                    <div class="msetex">運作管理系統</div>
                </a>
            </div>
            <div class="mserbox bgc_blue">
                <a href="/#/Content?type=7">
                    <div class="mseimg"><img src="/images/msv07.svg" alt=""/></div>
                    <div class="msetex">自動檢查</div>
                </a>
            </div>
            
            <div class="mserbox bgc_lpink msb_helf serhelv"></div>
            
            <div class="mserbox bgc_green">
                <a href="#/Content?type=9">
                    <div class="mseimg"><img src="/images/msv09.svg" alt=""/></div>
                    <div class="msetex">環安衛宣導</div>
                </a>
            </div>
            <div class="mserbox bgc_org">
                <a href="#/Content?type=10">
                    <div class="mseimg"><img src="/images/msv10.svg" alt=""/></div>
                    <div class="msetex">相關委員會</div>
                </a>
            </div>
            <div class="mserbox bgc_pink">
                <a href="#/Content?type=8">
                    <div class="mseimg"><img src="/images/msv08.svg" alt=""/></div>
                    <div class="msetex">其他業務</div>
                </a>
            </div>
            <div class="mserbox msb_helf bgc_lpink RWDnone"></div>
        </div>

    </div>
    
    <div class="newsall">
        <div class="newacon">
            <!--重要訊息-->
            <div class="nwsblk bgc_blue">
                <div class="nwstitbk bgc_blue">重要訊息</div> 
                <div class="nwscnb">

                    <div class="nwslisall">
                        <div class="nwlist" 
                            v-for="item in importantList" :key="item.startDate">
                            <a :href="'#/News/?tag=9&id=' + item.contentId" :title="item.title">
                                <div class="nwdate">
                                    {{ dateFormat(item.createDate) }}
                                    <span class="nwtg">【{{ item.contentClassName }}】</span>
                                    <span v-if="item.contentTagName.includes('校外')" class="nwtg_out">校外</span>
                                </div>
                                <div class="nwtxe">{{ item.title }}</div>
                            </a>
                        </div>
                        <div class="newmore">
                            <a href="#/News?tag=9" class="bgc_blue">More</a>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <!--校外訊息-->
            <div class="nwsblk bgc_green">
                <div class="nwstitbk bgc_green">訊息公告</div> 
                <div class="nwscnb">
                    <div class="nwslisall">
                        <div class="nwlist"
                            v-for="item in outList" :key="item.startDate">
                            <a :href="'#/News/?tag=10&id=' + item.contentId" :title="item.title">
                                <div class="nwdate">
                                    {{ dateFormat(item.createDate) }}
                                    <span class="nwtg">【{{ item.contentClassName }}】</span>
                                    <span v-if="item.contentTagName.includes('校外')" class="nwtg_out">校外</span>
                                </div>
                                <div class="nwtxe">{{ item.title }}</div>
                            </a>
                        </div>
                        <div class="newmore">
                            <a href="#/News" class="bgc_green">More</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <Footer :footer="footer"></Footer>
  </div>
</template>
<script>
import Header from "../../components/default/NavBar/Header_Index.vue";
import SideBar from "../../components/default/NavBar/SideBar.vue";
import Footer from "../../components/default/NavBar/Footer.vue";
export default {
  components: {
    Header,
    SideBar,
    Footer,
  },
  data() {
    return {
      header: {
        text1: "",
        text2: "",
      },
      sider: {
        index: 0,
        type: 0,
        list: [],
      },
      footer: { 
        index: true, 
      },
      pagination: {
        perPage: 5,
        page: 1,
      },
      importantList: [],
      outList: [],
      apiContent: (data) => this.userRequest.post("Eshntu/Content", data),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.importantList = [];
      let jsonImportant = {
        TYPE: "HOME_LIST",
        content_SELECT: {
          pageIndex: this.pagination.page,
          pageSize: this.pagination.perPage,
          contentTypeId: 1,
          contentTagId: 9,
          important: true,
        },
      };
      this.FunctionToken(this.GetImportantContent, jsonImportant);

      this.outList = [];
      let jsonOut = {
        TYPE: "HOME_LIST",
        content_SELECT: {
          pageIndex: this.pagination.page,
          pageSize: this.pagination.perPage,
          contentTypeId: 1,
          important: false,
        },
      };
      this.FunctionToken(this.GetOutContent, jsonOut);
    },
    GetImportantContent(data) {
      this.apiContent(data)
        .then((res) => {
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.importantList = jsonData.outData;
        }
      })
      .catch((err) => {
        //console.log(err);
      });
    },
    GetOutContent(data) {
      this.apiContent(data)
        .then((res) => {
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.outList = jsonData.outData;
        }
      })
      .catch((err) => {
        //console.log(err);
    });
    },
  },
};

</script>
