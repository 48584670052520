<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left"></SideLeft> 

        <div class="inmc_right">

          <div v-if="!detail">
              <!--單元內文介紹區-->
              <div class="conartical">

                  <div class="conal">
                      <div class="nwstpbr">
                          <div class="nwtbsstx">
                            共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料
                          </div>
                      </div>
                      <div class="nwstable">
                        <div class="nwstitle">
                            <div class="nwstb01">發佈時間</div>
                            <div class="nwstb02">公告主題</div>
                            <div class="nwstb03"></div>
                        </div>
                        <div class="nwscbar" v-if="loading">
                          <b-spinner label="Spinning"></b-spinner>
                        </div>
                        <div class="nwscbar" v-else-if="List.length == 0" >
                            <div class="nwstb01"></div>
                            <div class="nwstb02">
                              目前尚無資訊
                            </div>
                            <div class="nwstb03"></div>
                        </div>
                        <div v-else 
                          v-for="item in List" :key="item.contentId"
                          class="nwscbar"
                        >
                          <a
                            :href="'#/Police/News/?id=' + item.contentId"
                            title="在原視窗打開鏈結"
                          >
                            <div class="nwstb01">{{ dateFormat(item.createDate) }}</div>
                            <div class="nwstb02">{{ item.title }}</div>
                            <div class="nwstb03">{{ item.contentTagName }}</div>
                          </a>
                        </div>
                      </div>
                  </div>

                  <!--頁碼-->
                  <div class="twpage">
                    <div class="navigation">
                      <b-pagination
                        class="pagination light-theme simple-pagination"
                        v-model="pagination.page"
                        :total-rows="pagination.rows"
                        :per-page="pagination.perPage"
                        aria-controls="my-table"
                      ></b-pagination>
                    </div>
                  </div>
                  
              </div>
              <!--單元內文介紹區結束-->
          </div>

          <div v-else>
              <!--單元內文介紹區-->
              <div class="conartical">
                  <div class="conal">
                      <div class="articl">
                          <div class="nwsh1">{{ (newsDetail.contentTagName=="") ? "" : "(" + newsDetail.contentTagName + ")" }}{{ newsDetail.title }}</div>
                          <div class="update">
                            發佈日期：{{ dateFormat(newsDetail.createDate) }}  / 修改日期：{{ dateFormat(newsDetail.modifyDate) }}
                          </div>
                          <div class="artcblk" v-html="newsDetail.description" />
                      </div>
                      
                      <div class="backbtn"><a href="#/Police/News">返回列表</a></div>
                  </div>
              </div>
              <!--單元內文介紹區結束-->
          </div>

        </div>

    </div>  
    <!--主內文區塊結束-->  
  
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../../components/default/Police/NavBar/Header.vue";
import SideLeft from "../../../components/default/Police/NavBar/SideLeft.vue";
import SideBar from "../../../components/default/Police/NavBar/SideBar.vue";
import Footer from "../../../components/default/Police/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      detail: false,
      loading: false,
      tag: null,
      header: {
        text1: "駐警隊",
        text2: "最新消息",
      },
      sider: {
        index: 1,
        type: 0,
        list: [{ text: "最新消息", url: "" }],
      },
      left: {
        title: "最新消息",
        icon: "conmpc01.png",
        color: "txt_green",
        list: [],
      },
      footer: {},
      pagination: {
        perPage: 10,
        page: 1,
        rows: null,
      },
      search: {
        tag: null,
        classic: null,
      },
      List: [],
      newsDetail: {
        title: "",
      },
      apiContent: (data) => this.userRequest.post("Eshntu/Content", data),
      apiContentType: (data) => this.userRequest.post("Eshntu/ContentType", data),
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    init() {
      this.tag = this.$route.query.tag;

      let id = this.$route.query.id;
      if (id == undefined) {        
        window.scrollTo(0, 0);

        this.GetContentSearch(this.tag, null);
      } else {
        this.detail = true;

        let json = {
          TYPE: "HOME_DETAIL",
          content_SELECT: {
            contentId: id,
            contentTypeId: 2,
          },
        };
        this.FunctionToken(this.GetContentDetail, json);
      }
    },
    GetContentSearch(tag, classic) {

      this.detail = false;
      this.loading = true;
      this.List = [];

      let json = {
        TYPE: "HOME_LIST",
        content_SELECT: {
          pageIndex: this.pagination.page,
          pageSize: this.pagination.perPage,
          contentTypeId: 2,
        },
      };
      this.FunctionToken(this.GetContent, json);
    },
    GetContent(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            
            this.List = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
            this.loading = false;

            this.sider.list = [{ text: "最新消息", url: "" }];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetContentDetail(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.newsDetail = jsonData;
            
            if(this.newsDetail.url!=null) {
              if(this.newsDetail.linkMethod == "_self") {
                window.location.href = "#"+this.newsDetail.url.replace("/#/","").replace("#/","");
              } else {
                window.open(this.newsDetail.url);
                this.$router.go(-1);
              }
            }

            this.sider.list = [
                            { text: "最新消息", url: "#/Police/News" },
                            { text: this.newsDetail.title, url: "" }
                          ];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
