<template>
  <div>
    <Header :header="header"></Header>
    <div class="tpview">
        <SideBar :sider="sider"></SideBar>
        <div class="mtitall">
            <h1 class="ntulogo"></h1>
            <h2 class="mtit01"></h2>
            <div class="mtit02"></div>
        </div>
        
        <div class="antm">
            <video loop muted="" poster="images/bkcnpc.png" autoplay="autoplay" playsinline="" class="mvimage" >
              <source src="images/indanm.mp4" type="video/mp4">
            </video>
        </div>
    </div>
    
    <div class="mservice">
        <div class="mserbar">
            <div class="mserbox bgc_lpink RWDnone"></div>
            <div class="mserbox bgc_pink">
                <a href="#/EN/Content?type=17">
                    <div class="mseimg"><img src="/images/msv01.svg" alt=""/></div>
                    <div class="msetex">Training</div>
                </a>
            </div>
            <div class="mserbox bgc_blue">
                <a href="#/EN/Content?type=18">
                    <div class="mseimg"><img src="/images/msv02.svg" alt=""/></div>
                    <div class="msetex">Health Management</div>
                </a>
            </div>
            <div class="mserbox bgc_org">
                <a href="#/EN/Content?type=19">
                    <div class="mseimg"><img src="/images/msv04.svg" alt=""/></div>
                    <div class="msetex">Laboratories</div>
                </a>
            </div>
            <div class="mserbox bgc_lblue RWDnone"></div>
        </div>

    </div>
    
    <Footer :footer="footer"></Footer>
  </div>
</template>
<script>
import Header from "../../../components/default/NavBar_EN/Header_Index.vue";
import SideBar from "../../../components/default/NavBar_EN/SideBar.vue";
import Footer from "../../../components/default/NavBar_EN/Footer.vue";
export default {
  components: {
    Header,
    SideBar,
    Footer,
  },
  data() {
    return {
      header: {
        text1: "",
        text2: "",
      },
      sider: {
        index: 0,
        type: 0,
        list: [],
      },
      footer: { 
        index: true, 
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
  },
};

</script>
