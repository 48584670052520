<template>
  <div>
    <h3 class="inbtitnm m-5">{{ header.text1 }}</h3>
    <b-container>
      <b-row>
        <div class="bkcnpc">
          <img src="/images/backend/bkcnpc.png" alt=""/>
        </div>
        <b-col>
          <b-form class="mt-5">
            <b-form-group label="帳號" label-for="username" class="text-left">
              <b-form-input id="username"
                type="text"
                placeholder="請輸入帳號"
                v-model="login.acc"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="密碼" label-for="pawd" class="text-left">
              <b-form-input id="pawd"
                :type="password.type"
                placeholder="請輸入密碼"
                v-model="login.pwd"
              ></b-form-input>
              <a href="#" @click="showPwdEye">
                <img class="showeye"
                    v-bind:src="'/images/backend/' + password.image" alt="顯示或隱藏明碼"/></a>
            </b-form-group>
            <b-row
              ><b-col>
                <b-form-group
                  label="圖形驗證碼"
                  label-for="code"
                  class="text-left"
                >
                  <b-form-input
                    id="code"
                    type="number"
                    placeholder="請輸入圖形驗證碼"
                    v-model="login.code"
                  ></b-form-input> </b-form-group></b-col
              ><b-col> <img width="100%" :src="imageCode" class="mb-3" alt="驗證碼圖形" /></b-col
            ></b-row>
            <b-row>
              <b-col>
                <b-button size="lg" @click="userLogin" variant="success"
                  >登入</b-button
                >
              </b-col>
              <b-col>
                <b-button size="lg" v-b-modal.modal-forgetPwd variant="success"
                  >忘記密碼</b-button
                >
                <b-modal
                  id="modal-forgetPwd"
                  v-model="forgetPwdShow"
                  title="忘記密碼"
                  ok-only
                >
                  <template #modal-footer>
                    <button
                      v-b-modal.modal-close_visit
                      class="btn btn-success btn-sm m-1"
                      @click="functionForgetPwd"
                    >
                      送出
                    </button>
                  </template>
                  <p>
                    送出資料後，系統將以電子郵件通知臨時密碼，請於密碼有效期內登入系統重設密碼。
                  </p>
                  <p>
                    以下欄位均為必填欄位，請填入正確資料。若忘記帳號，請洽內政部。
                  </p>
                  <b-row>
                    <b-col>
                      <label for="title">帳號</label>
                      <b-form-input
                        id="title"
                        v-model="forgetPwd.acc"
                        type="text"
                        placeholder="請輸入帳號"
                      ></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row
                    ><b-col>
                      <label for="code">驗證碼</label>
                      <b-form-input
                        id="code"
                        type="number"
                        placeholder="請輸入圖形驗證碼"
                        v-model="forgetPwd.code"
                      ></b-form-input>
                      <a
                        href="javascript:void(0)"
                        class="reload"
                        title="點我可重新產生驗證碼"
                        @click="getImageCode"
                        ><img
                          src="/images/backend/icon-restart.png"
                          alt="點我可重新產生驗證碼" /></a></b-col
                    ><b-col>
                      <img width="100%" :src="imageCode" class="mb-3" /></b-col
                  ></b-row>
                </b-modal>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/admin/NavBar/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      header: {
        text1: '國立臺灣大學環境保護暨職業安全衛生中心',
      },
      sideBarActive: '',
      imageCode: "",
      login: {
        acc: null,
        pwd: null,
        code: null,
      },
      password: {
        show: false,
        type: "password",
        image: "eye.png",
      },
      forgetPwd: {
        acc: null,
        code: null,
      },
      forgetPwdShow: false,
      apiGetImageCode: (data) =>
        this.userRequest.post("Eshntu/GetImageCode", data),
      apiBackendLogin: (data) =>
        this.userRequest.post("Eshntu/BackendLogin", data),
      apiUserInfo: (data) =>
        this.userRequest.post("Eshntu/UserInfo", data),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getImageCode();
      let json = {
        TYPE: "CHECKSESSION",
        login_SELECT: {},
      }
      this.FunctionToken(this.checkLogin, json);

      this.showPwdEye();
    },
    userLogin() {
      let json = {
        TYPE: "ADMINLOGIN",
        login_SELECT: {
          Id: this.login.acc,
          EnCodePass: this.login.pwd,
          ImgCodePass: this.login.code
        },
      }
      this.FunctionToken(this.Login, json);
    },
    functionForgetPwd() {
      let json = {
        TYPE: "FORGET",
        user_info_FORGET: {
          userAccount: this.forgetPwd.acc,
          ImgCodePass: this.forgetPwd.code
        },
      }
      //console.log(json)
      this.FunctionToken(this.ForgetPwd, json);
    },
    //-----------api----------------
    getImageCode() {
      this.apiGetImageCode()
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            let jsonImage = JSON.parse(json.Data);
            this.imageCode = jsonImage.Image;
            this.imageCodeArr = jsonImage.Code.split("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkLogin(data) {
      this.apiBackendLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            this._go("/backend/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Login(data) {
      this.apiBackendLogin(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          this.getImageCode();
          if (json.Status) {
            sessionStorage.setItem("LoginName", json.Data);
            this._go("/backend/");
          } else if (json.Data.includes("/ForgetPwd?")) {
            alert("密碼過期（超過六個月未變更）");
            this._go(json.Data);
          } else {
            this.login.code = "";
            alert(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ForgetPwd(data) {
      this.apiUserInfo(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          this.getImageCode();
          if (json.Status) {
            this.forgetPwdShow = false;
            alert('修改密碼連結郵件已寄送至使用者帳號設定之電子郵件信箱。')
          } else {
            alert(json.Data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
};
</script>
