import Vue from "vue";
import VueRouter from "vue-router";
import admin from "../views/admin/header.vue";
//前台
import Home from '../views/default/Home.vue';
import News from '../views/default/News.vue';
import Content from '../views/default/Content.vue';
import About from '../views/default/About.vue';
import Law from '../views/default/Law.vue';
import Download from '../views/default/Download.vue';
import Power from '../views/default/Power.vue';
import Sitemap from '../views/default/Sitemap.vue';
import Privpage from '../views/default/Privpage.vue';
import Schedule from '../views/default/Schedule.vue';
//前台-英文版
import English_Home from '../views/default/English/Home.vue';
import English_News from '../views/default/English/News.vue';
import English_Content from '../views/default/English/Content.vue';
import English_About from '../views/default/English/About.vue';
import English_Workgroup from '../views/default/English/Workgroup.vue';
import English_Sitemap from '../views/default/English/Sitemap.vue';
//駐警隊
import Police_Home from '../views/default/Police/Home.vue';
import Police_News from '../views/default/Police/News.vue';
import Police_Content from '../views/default/Police/Content.vue';
import Police_Lose from '../views/default/Police/Lose.vue';
import Police_FAQ from '../views/default/Police/FAQ.vue';
import Police_Download from '../views/default/Police/Download.vue';
//後台
import Admin_Home from '../views/admin/Home.vue';
import Admin_ForgetPwd from '../views/admin/ForgetPwd.vue';
import Admin_Login from '../views/admin/Login.vue';
import Admin_Account from '../views/admin/Account.vue';
import Admin_Content from '../views/admin/Content.vue';
import Admin_FAQ from '../views/admin/FAQ.vue';
import Admin_Business from '../views/admin/Business.vue';
import Admin_PrivateContent from '../views/admin/PrivateContent.vue';
import Admin_Download from '../views/admin/Download.vue';
import Admin_Logout from '../views/admin/Logout.vue';

import MiddlewareStylesheet from "./middleware/stylesheet";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/News',
    name: 'News',
    component: News,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/Content',
    name: 'Content',
    component: Content,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/About',
    name: 'About',
    component: About,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/Law',
    name: 'Law',
    component: Law,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/Download',
    name: 'Download',
    component: Download,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/Schedule',
    name: 'Schedule',
    component: Schedule,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/Power',
    name: 'Power',
    component: Power,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/Sitemap',
    name: 'Sitemap',
    component: Sitemap,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: '/Privpage',
    name: 'Privpage',
    component: Privpage,
    meta: {
      stylesheet: "esh",
      title: '國立臺灣大學環境保護暨職業安全衛生中心'
    }
  },
  {
    path: "/EN",
    name: "English_Home",
    component: English_Home,
    meta: {
      stylesheet: "en",
      title: 'ENVIRONMENTAL PROTECTION & OCCUPATIONAL SAFETY & HEALTH CENTER '
    }
  },
  {
    path: '/EN/News',
    name: 'English_News',
    component: English_News,
    meta: {
      stylesheet: "en",
      title: 'ENVIRONMENTAL PROTECTION & OCCUPATIONAL SAFETY & HEALTH CENTER '
    }
  },
  {
    path: '/EN/Content',
    name: 'English_Content',
    component: English_Content,
    meta: {
      stylesheet: "en",
      title: 'ENVIRONMENTAL PROTECTION & OCCUPATIONAL SAFETY & HEALTH CENTER '
    }
  },
  {
    path: '/EN/About',
    name: 'English_About',
    component: English_About,
    meta: {
      stylesheet: "en",
      title: 'ENVIRONMENTAL PROTECTION & OCCUPATIONAL SAFETY & HEALTH CENTER '
    }
  },
  {
    path: '/EN/Workgroup',
    name: 'English_Workgroup',
    component: English_Workgroup,
    meta: {
      stylesheet: "en",
      title: 'ENVIRONMENTAL PROTECTION & OCCUPATIONAL SAFETY & HEALTH CENTER '
    }
  },
  {
    path: '/EN/Sitemap',
    name: 'English_Sitemap',
    component: English_Sitemap,
    meta: {
      stylesheet: "en",
      title: 'ENVIRONMENTAL PROTECTION & OCCUPATIONAL SAFETY & HEALTH CENTER '
    }
  },
  {
    path: "/Police",
    name: "Police_Home",
    component: Police_Home,
    meta: {
      stylesheet: "police",
      title: '國立臺灣大學環境保護暨職業安全衛生中心駐警隊'
    }
  },
  {
    path: '/Police/News',
    name: 'Police_News',
    component: Police_News,
    meta: {
      stylesheet: "police",
      title: '國立臺灣大學環境保護暨職業安全衛生中心駐警隊'
    }
  },
  {
    path: '/Police/Content',
    name: 'Police_Content',
    component: Police_Content,
    meta: {
      stylesheet: "police",
      title: '國立臺灣大學環境保護暨職業安全衛生中心駐警隊'
    }
  },
  {
    path: '/Police/Lose',
    name: 'Police_Lose',
    component: Police_Lose,
    meta: {
      stylesheet: "police",
      title: '國立臺灣大學環境保護暨職業安全衛生中心駐警隊'
    }
  },
  {
    path: '/Police/FAQ',
    name: 'Police_FAQ',
    component: Police_FAQ,
    meta: {
      stylesheet: "police",
      title: '國立臺灣大學環境保護暨職業安全衛生中心駐警隊'
    }
  },
  {
    path: '/Police/Download',
    name: 'Police_Download',
    component: Police_Download,
    meta: {
      stylesheet: "police",
      title: '國立臺灣大學環境保護暨職業安全衛生中心駐警隊'
    }
  },
  {
    path: '/ForgetPwd',
    name: 'ForgetPwd',
    component: Admin_ForgetPwd,
    meta: {
      stylesheet: "backside",
      title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
    }
  },
  {
    path: '/ISY',
    name: 'ISY',
    component: Admin_Login,
    meta: {
      stylesheet: "backside",
      title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
    }
  },
  {
    path: "/backend",
    component: admin,
    children: [
      {
        path: "/",
        name: "Admin_Home",
        component: Admin_Home,
        meta: {
          stylesheet: "backside",
          title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
        }
      },
      {
        path: '/backend/Account',
        name: 'Admin_Account',
        component: Admin_Account,
        meta: {
          stylesheet: "backside",
          title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
        }
      },
      {
        path: '/backend/Content',
        name: 'Admin_Content',
        component: Admin_Content,
        meta: {
          stylesheet: "backside",
          title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
        }
      },
      {
        path: '/backend/FAQ',
        name: 'Admin_FAQ',
        component: Admin_FAQ,
        meta: {
          stylesheet: "backside",
          title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
        }
      },
      {
        path: '/backend/Business',
        name: 'Admin_Business',
        component: Admin_Business,
        meta: {
          stylesheet: "backside",
          title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
        }
      },
      {
        path: '/backend/PrivateContent',
        name: 'Admin_PrivateContent',
        component: Admin_PrivateContent,
        meta: {
          stylesheet: "backside",
          title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
        }
      },
      {
        path: '/backend/Download',
        name: 'Admin_Download',
        component: Admin_Download,
        meta: {
          stylesheet: "backside",
          title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
        }
      },
      {
        path: '/backend/Logout',
        name: 'Admin_Logout',
        component: Admin_Logout,
        meta: {
          stylesheet: "backside",
          title: '國立臺灣大學環境保護暨職業安全衛生中心管理系統'
        }
      },
    ],
  },
];

const router = new VueRouter({
  //mode: 'history',
  //base: process.env.BASE_URL,
  routes,
});

router.beforeEach(MiddlewareStylesheet);

export default router;