
<template>
  <div class="contall">
    <div class="mconarall">
      <div class="Customize">
        <nav class="navbar Customize navbar-light">
          <a class="navbar-brand" href="#/ISY/"
            ><img
              src="/images/favicon/apple-touch-icon.png"
              alt="首頁圖示"
              class="logo_01"
            />
            國立臺灣大學環境保護暨職業安全衛生中心</a
          >
        </nav>
        <div class="sub-title">後台管理系統-{{ header.text1 }}</div>
      </div>
      <b-form-group class="mt-1" style="margin-left: 20%;">
        <b-row style="width: 50%; margin: 2% !important;display: inherit;">
          <b-col> 
          <label>請輸入您的新密碼</label>
          <p>{{ header.note1 }}</p></b-col>
          <b-col
            ><b-form-input
              v-model="pwd1"
              type="password"
              placeholder="請輸入您的新密碼"
              required
            ></b-form-input
          ></b-col>
        </b-row>
      </b-form-group>
      <b-form-group class="mt-1" style="margin-left: 20%;">
        <b-row style="width: 50%;margin: 2% !important;display: inherit;"
          ><b-col> <label>請再次輸入您的新密碼</label></b-col
          ><b-col>
            <b-form-input
              v-model="pwd2"
              type="password"
              placeholder="請再次輸入您的新密碼"
              required
            ></b-form-input></b-col
        ></b-row>
      </b-form-group>
      <b-form-group class="mt-1" style="margin-left: 20%;">
        <b-row style="width: 50%;margin: 2% !important;display: inherit;">
          <b-col> <label>驗證碼</label></b-col>
          <b-col>
            <img
              :src="imageCode"
              alt="captchaImage"
              class="captcha"
              style="height: 50%" />
            <input type="tel" maxlength="6" class="passtext mx-2" v-model="code" />
            <a
              href="javascript:void(0)"
              class="reload"
              title="點我可重新產生驗證碼"
              @click="getImageCode"
              ><img src="/images/backend/icon-restart.png" alt="點我可重新產生驗證碼"
            /></a>
            <a
              href="javascript:void(0)"
              @click="speakWord"
              class="play"
              title="點我可播放語音服務"
              ><img src="/images/backend/icon-volume.png" alt="點我可播放語音服務"
            /></a>
            <audio id="vcode_mp3" src="/captcha.mp3?2022061703260100509"></audio
          ></b-col>
        </b-row>
        <b-row style="width: 80%;margin: 2% !important;display: inherit;">
          <b-button @click="reset">儲存</b-button>
        </b-row>
      </b-form-group>
      <b-modal v-model="model.show" title="提示">
        <template #modal-footer>
          <button
            v-b-modal.modal-close_visit
            class="btn btn-success btn-sm m-1"
            @click="model.show = !model.show"
          >
            確定
          </button>
        </template>
        <p class="my-4">{{ model.text }}</p>
      </b-modal>
      <b-modal v-model="completeModel.show" title="提示">
        <template #modal-footer>
          <button
            v-b-modal.modal-close_visit
            class="btn btn-success btn-sm m-1"
            @click="resetComplete()"
          >
            確定
          </button>
        </template>
        <p class="my-4">更新成功!</p>
      </b-modal>
      <b-modal v-model="errorModelShow" title="提示">
        <template #modal-footer>
          <button
            v-b-modal.modal-close_visit
            class="btn btn-success btn-sm m-1"
            @click="_go('/')"
          >
            確定
          </button>
        </template>
        <p class="my-4">{{ errorMsg }}</p>
      </b-modal>
      <Footer></Footer>
    </div>
  </div>
</template>
<style lang="css" scoped>
.Customize {
  background: linear-gradient(180deg, #92b267 0%, #54792b 100%) !important;
  line-height: 1 !important;
  height: auto;
}
.Customize .navbar-brand {
  color: #fff;
  font-size: 2.2em;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.Customize .logo_01 {
  height: 64px;
  width: auto;
  margin-right: 10px;
}
.Customize .sub-title {
  background-color: #e7e7e7;
  font-size: 2.2em;
  text-align: center;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}
</style>
<script>
//語音套件
const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
export default {
  data() {
    return {
      header: {
        text1: '忘記密碼',
        note1: '(密碼長度6-12個字元，以英文大小寫、數字等限定，需至少一位大寫、一位小寫、一位數字。)',
      },
      type: null,
      PassToken: null,
      pwd1: null,
      pwd2: null,
      code: null,
      imageCode: null,
      imageCodeArr: null,
      model: {
        show: false,
        text: null
      },
      completeModel: {
        show: false,
        text: null
      },
      errorModelShow: false,
      errorMsg: null,
      apiGetImageCode: (data) =>
        this.userRequest.post("Eshntu/GetImageCode", data),
      apiBackendLogin: (data) =>
        this.userRequest.post("Eshntu/BackendLogin", data),
      apiUserInfo: (data) =>
        this.userRequest.post("Eshntu/UserInfo", data),
    };
  },
  mounted() {
    this.init();
  },
  watch: {
  },
  methods: {
    init() {
      this.getImageCode();
      this.PassToken = this.$route.query.Token;
      this.type = this.$route.query.Type;
      
      if (this.type == 'BackEnd') {
        this.header.text1 = '忘記後台密碼';
        this.header.note1 = '(密碼長度6個字元~12個字，以英文大小寫、數字等限定，需至少一位大寫、一位小寫、一位數字。)';
        let json = {
          TYPE: "CHECKPASS",
          User_Info_FORGET_PASS: {
            PassToken: this.PassToken,
          },
        }
        this.FunctionToken(this.CheckUserInfoToken, json);
      } 
      else if (this.type == 'BackEndChange') {
        this.header.text1 = '變更後台密碼';
        this.header.note1 = '(密碼長度6個字元~12個字，以英文大小寫、數字等限定，需至少一位大寫、一位小寫、一位數字。)';
        let json = {
          TYPE: "CHECKPASS",
          User_Info_FORGET_PASS: {
            PassToken: this.PassToken,
          },
        }
        this.FunctionToken(this.CheckUserInfoToken, json);
      }
      else {
        alert('error')
      }
    },
    speakWord() {
      this.handleSpeak(this.imageCodeArr);
    },
    //語音套件
    handleSpeak(text) {
      msg.text = text;
      msg.lang = "zh-CN";
      msg.voume = 1;
      msg.rate = 1;
      msg.pitch = 1;
      synth.speak(msg);
    },
    reset() {
      if (this.pwd1 == null || this.pwd1 == "") {
        this.model.show = true;
        this.model.text = '密碼不得為空';
      } else {
        if (this.pwd1 === this.pwd2) {
          
          if (this.type == 'BackEnd') {
            let json = {
              TYPE: "FORGET_CHANGEPASS",
              User_Info_FORGET_PASS: {
                PassToken: this.PassToken,
                DecryptionPass: this.pwd1,
                ImgCodePass: this.code,
              },
            }
            this.FunctionToken(this.ResetUserInfoPwd, json);
          } else if (this.type == 'BackEndChange') {
            let json = {
              TYPE: "RESETPASS",
              User_Info_RESET_PASS: {
                PassToken: this.PassToken,
                DecryptionPass: this.pwd1,
                ImgCodePass: this.code,
              },
            }
            this.FunctionToken(this.ResetUserInfoPwd, json);
          }
        } else {
          this.model.show = true;
          this.model.text = '請確認密碼是否相同';
          this.init();
        }
      }
    },
    resetComplete() {
      if (this.type == 'BackEnd') {
        this._go('/ISY');
      } else if (this.type == 'BackEndChange') {
        this._go('/backend/');
      }
    },
    //api
    getImageCode() {
      this.apiGetImageCode()
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            let jsonImage = JSON.parse(json.Data);
            this.imageCode = jsonImage.Image;
            this.imageCodeArr = jsonImage.Code.split("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ResetLoginPwd(data) {
      this.apiBackendLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          //console.log(json)
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            //console.log(jsonData)
            this.completeModel.show = true;
            this.completeModel.text = json.Data;
          } else {
            this.init();
            this.model.show = true;
            this.model.text = json.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ResetUserInfoPwd(data) {
      this.apiUserInfo(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          //console.log(json)
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            //console.log(jsonData)
            this.completeModel.show = true;
            this.completeModel.text = json.Data;
          } else {
            this.init();
            this.model.show = true;
            this.model.text = json.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    CheckLoginToken(data) {
      this.apiBackendLogin(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          //console.log(json)
          if (!json.Status) {
            this.errorMsg = json.Data;
            this.errorModelShow = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    CheckUserInfoToken(data) {
      this.apiUserInfo(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          //console.log(json)
          if (!json.Status) {
            this.errorMsg = json.Data;
            this.errorModelShow = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
