<template>
  <div>
    <!-- {{pageLocation}} -->
    <div class="accordion">
      <div class="menu-title">
        主選單列表
        <small>MENU</small>
      </div>
      <!-- 第一層 -->
      <ul class="list-unstyled" role="tablist">
        <template v-for="(item, index) in menu">
          <template v-if="item.second != null">
            <li :key="index">
              <button
                class="accordion-btn gradient"
                v-b-toggle="'accordion-' + index"
              >
                <b-icon
                  class="accordion-arrow"
                  icon="caret-right-fill"
                ></b-icon>
                {{ item.title }}
                <b-badge v-if="item.construction" variant="warning"
                  >尚未開發</b-badge
                >
              </button>
              <b-collapse
                tag="ul"
                :id="'accordion-' + index"
                accordion="first-accordion"
                role="firstPanel"
                class="list-unstyled"
              >
                <!-- 第二層 -->
                <template v-for="(secondItem, secondIndex) in item.second">
                  <template v-if="secondItem.third != null">
                    <li :key="secondIndex">
                      <button
                        class="accordion-btn"
                        v-b-toggle="'secondAccordion-' + index + secondIndex"
                      >
                        <b-icon
                          class="accordion-arrow"
                          icon="caret-right-fill"
                        ></b-icon>
                        {{ secondItem.title }}
                        <b-badge
                          v-if="secondItem.construction"
                          variant="warning"
                          >尚未開發</b-badge
                        >
                      </button>
                      <b-collapse
                        tag="ul"
                        :id="'secondAccordion-' + index + secondIndex"
                        accordion="second-accordion"
                        role="secondpanel"
                        class="list-unstyled"
                      >
                        <!-- 第三層 -->
                        <li
                          v-for="(thirdItem, thirdIndex) in secondItem.third"
                          :key="thirdIndex"
                          :class="{ active: thirdItem.to == pageLocation }"
                        >
                          <a
                            class="accordion-btn"
                            style="padding-left: 52px"
                            :href="thirdItem.to"
                            @click="getPageLocation()"
                          >
                            {{ thirdItem.title }}
                            <b-badge
                              v-if="thirdItem.construction"
                              variant="warning"
                              >尚未開發</b-badge
                            >
                          </a>
                        </li>
                      </b-collapse>
                    </li>
                  </template>
                  <template v-else>
                    <li
                      :key="secondIndex"
                      :class="{ active: secondItem.to == pageLocation }"
                    >
                      <a
                        class="accordion-btn"
                        style="padding-left: 36px"
                        :href="secondItem.to"
                        @click="getPageLocation()"
                      >
                        {{ secondItem.title }}
                        <b-badge
                          v-if="secondItem.construction"
                          variant="warning"
                          >尚未開發</b-badge
                        >
                      </a>
                    </li>
                  </template>
                </template>
              </b-collapse>
            </li>
          </template>
          <template v-else>
            <li :key="index">
              <a
                class="accordion-btn gradient"
                style="padding-left: 36px"
                :href="item.to"
              >
                {{ item.title }}
              </a>
            </li>
          </template>
        </template>
      </ul>
    </div>

    <div class="accordion" style="display: none" role="tablist">
      <div class="menu-title">
        主選單列表
        <small>MENU</small>
      </div>

      <div v-for="(item, index) in menu" :key="index">
        <template v-if="item.second != null">
          <b-button
            class="append d-flex align-items-center m-0 px-3"
            block
            v-b-toggle="'accordion-' + index"
          >
            {{ item.title }}</b-button
          >
          <b-collapse :id="'accordion-' + index">
            <b-nav
              v-for="(secondItem, secondIndex) in item.second"
              :key="secondIndex"
            >
              <template v-if="secondItem.third != null">
                <b-button
                  class="secondAppend d-flex align-items-center m-0"
                  block
                  v-b-toggle="'secondAccordion-' + index + secondIndex"
                >
                  <b-icon
                    icon="arrow-right-circle-fill"
                    style="height: 16px"
                    class="mr-1"
                  ></b-icon>
                  {{ secondItem.title }}</b-button
                >
                <b-collapse :id="'secondAccordion-' + index + secondIndex">
                  <b-nav vertical class="mb-0">
                    <b-nav-item
                      v-for="(thirdItem, thirdIndex) in secondItem.third"
                      :key="thirdIndex"
                      :href="thirdItem.to"
                      link-classes="text-dark d-flex align-items-center third-nav-item"
                    >
                      <b-icon
                        icon="arrow-right-circle-fill"
                        style="height: 16px"
                        class="mr-1"
                      ></b-icon>
                      {{ thirdItem.title }}</b-nav-item
                    >
                  </b-nav>
                </b-collapse>
              </template>
              <template v-else>
                <b-nav vertical>
                  <b-nav-item
                    :href="secondItem.to"
                    link-classes="text-dark d-flex align-items-center second-nav-item"
                  >
                    <b-icon
                      icon="arrow-right-circle-fill"
                      style="height: 16px"
                      class="mr-1"
                    ></b-icon>
                    {{ secondItem.title }}</b-nav-item
                  >
                </b-nav>
              </template>
            </b-nav>
          </b-collapse>
        </template>
        <template v-else>
          <b-button class="append" block :href="item.to">{{
            item.title
          }}</b-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageLocation: window.location.hash,
      page: 'home',
      menu: [],
      nacActive: [],
      navTitle: null,
      backgroundActive: [],
      phoneBarOpen: false,
      apiProject: (data) =>
        this.userRequest.post("Eshntu/Project", data),
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.query'() {
      this.getPageLocation();
    },
  },
  methods: {
    getPageLocation: function () {
      this.pageLocation = window.location.hash
    },
    init() {
      let json = {
        TYPE: "SIDEBAR_OPTION",
      }
      this.FunctionToken(this.GetSideBar, json);
    },
    GetSideBar(data) {
      this.apiProject(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.menu = jsonData;
            this.menu.push({
              title: '個人資訊',
              to: '#/backend/Account/?type=9',
              second: null
            });
            this.menu.push({
              title: '登出',
              to: '#/backend/Logout',
              second: null
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
};
</script>