<template>

  <div>
     <Header :header="header"></Header>
     <SideBar :sider="sider"></SideBar>
     <!--主內文區塊-->
     <div class="inmcont">
          <SideLeft :left="left"></SideLeft>

          <div class="inmc_right">

              <!--單元內文介紹區-->
              <div class="conartical">
                  <div class="conal">
                      <div class="nwstpbr">
                          <div class="nwtbsstx">
                            共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料
                          </div>
                      </div>
                      <div class="articl">
                          <div class="downall">
                            <div class="dwbar" v-if="loading">
                              <b-spinner label="Spinning"></b-spinner>
                            </div>
                            <div class="dwbar" v-else-if="List.length == 0" >
                                <div class="dwtitle"></div>
                                <div class="dwstyle">
                                  目前尚無資訊
                                </div>
                            </div>
                             <div class="dwbar" 
                                  v-else 
                                  v-for="item in List" :key="item.downloadId">
                                 <div class="dwtitle">{{ item.title }}</div>
                                 <div class="dwstyle">
                                     <div class="dwbtn2"
                                        v-for="file in item.outExFile" :key="file.filePath">
                                          <a :href="'/fileStore'+file.filePath" :title="file.fileName+'(另開新視窗)'" target="_blank">{{ file.fileExt.toUpperCase() }}</a></div>
                                 </div>
                             </div> 
                          </div>
                      </div>
                    
                      
                  </div>
              </div>
              <!--單元內文介紹區結束-->
          </div>
      </div>
       <!--主內文區塊結束--> 

      <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../../components/default/Police/NavBar/Header.vue";
import SideLeft from "../../../components/default/Police/NavBar/SideLeft.vue";
import SideBar from "../../../components/default/Police/NavBar/SideBar.vue";
import Footer from "../../../components/default/Police/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      id: null,
      detail: false,
      loading: false,
      header: {
        text1: "駐警隊",
        text2: "下載專區",
      },
      sider: {
        index: 7,
        type: 0,
        list: [{ text: "下載專區", url: "" }],
      },
      left: {
        title: "下載專區",
        icon: "conmpc07.png",
        color: "txt_org",
        list: [],
      },
      footer: {},
      pagination: {
        perPage: 30,
        page: 1,
        rows: 5,
      },
      search: {},
      List: [],
      contentDetail: {},
      apiDownload: (data) => this.userRequest.post("Eshntu/Download", data),
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    init() {     
      window.scrollTo(0, 0);
      this.loading = true;
      this.List = [];

      let json = {
        TYPE: "HOME_LIST",
        download_SELECT: {
          pageIndex: this.pagination.page,
          pageSize: this.pagination.perPage,
          downloadTypeId: 1,
        },
      };
      this.FunctionToken(this.GetContent, json);    
    },
    GetContent(data) {
      this.apiDownload(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            
            this.List = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
