<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left"></SideLeft>

        <div class="inmc_right">

            <!--單元內文介紹區-->
            <div class="conartical">
                <div class="stmpall">
                    <div class="mapbk">
                        <div class="mpbtit">Main</div>
                        <div class="mpblsbkal">
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#">Training</a></div>
                                <ul class="mplsmal">      
                                  <li class="mplsmh2"
                                      v-for="item in List_9_17" 
                                          :key="item.businessId">
                                      <a :href="'#/EN/Content?type='+'17'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#">Health Management</a></div>
                                <ul class="mplsmal">
                                  <li class="mplsmh2"
                                      v-for="item in List_9_18" 
                                          :key="item.businessId">
                                      <a :href="'#/EN/Content?type='+'18'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#">Laboratories</a></div>
                                <ul class="mplsmal">
                                  <li class="mplsmh2"
                                      v-for="item in List_9_19" 
                                          :key="item.businessId">
                                      <a :href="'#/EN/Content?type='+'19'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mphfall">
                        <div class="mapbk mphelf">
                            <div class="mpbtit">Latest News</div>
                            <div class="mpblsbkal">
                                <div class="mplstit"><a href="#/EN/News">News</a></div> 
                            </div>
                        </div>

                        <div class="mapbk mphelf">
                            <div class="mpbtit">About EHS</div>
                            <div class="mpblsbkal">
                                <div class="mplstit"
                                  v-for="item in List_10_20" 
                                    :key="item.businessId"><a :href="'#/EN/About?type='+'20'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></div> 
                            </div>
                        </div>
                    
                        <div class="mapbk mphelf">
                            <div class="mpbtit">Work Group</div>
                            <div class="mpblsbkal">
                                <div class="mplstit"
                                  v-for="item in List_11_21" 
                                    :key="item.businessId"><a :href="'#/EN/Workgroup?type='+'21'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></div> 
                            </div>
                        </div>
                    </div>
                    
                </div>    
                      
            </div>
            <!--單元內文介紹區結束-->
        </div>
    </div>  
    <!--主內文區塊結束-->  
        
    <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
     
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../../components/default/NavBar_EN/Header.vue";
import SideLeft from "../../../components/default/NavBar_EN/SideLeft.vue";
import SideBar from "../../../components/default/NavBar_EN/SideBar.vue";
import Footer from "../../../components/default/NavBar_EN/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      detail: false,
      loading: false,
      tag: null,
      header: {
        text1: "Siteamp",
        text2: null,
      },
      sider: {
        index: 2,
        type: 0,
        list: [{ text: "Siteamp", url: "" }],
      },
      left: {
        title: "Siteamp",
        icon: "mnu07.svg",
        color: "bgc_blue",
        list: [],
      },
      List_9_17: [],
      List_9_18: [],
      List_9_19: [],
      List_10_20: [],
      List_11_21: [],
      footer: {},
      apiBusiness: (data) => this.userRequest.post("Eshntu/Business", data),
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
  },
  methods: {
    init() {
        this.getContentList_9_17();
        this.getContentList_9_18();
        this.getContentList_9_19();
        this.getContentList_10_20();
        this.getContentList_11_21();
    },
    getContentList_9_17() {
      let json = {
        TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 9,
            businessOneId: 17,
          },
      };
      this.FunctionToken(this.functionContentList_9_17, json);
    },
    functionContentList_9_17(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_9_17 = jsonData;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContentList_9_18() {
      let json = {
        TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 9,
            businessOneId: 18,
          },
      };
      this.FunctionToken(this.functionContentList_9_18, json);
    },
    functionContentList_9_18(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_9_18 = jsonData;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContentList_9_19() {
      let json = {
        TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 9,
            businessOneId: 19,
          },
      };
      this.FunctionToken(this.functionContentList_9_19, json);
    },
    functionContentList_9_19(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_9_19 = jsonData;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContentList_10_20() {
      let json = {
        TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 10,
            businessOneId: 20,
          },
      };
      this.FunctionToken(this.functionContentList_10_20, json);
    },
    functionContentList_10_20(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_10_20 = jsonData;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContentList_11_21() {
      let json = {
        TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 11,
            businessOneId: 21,
          },
      };
      this.FunctionToken(this.functionContentList_11_21, json);
    },
    functionContentList_11_21(data) {
      this.apiBusiness(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_11_21 = jsonData;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
