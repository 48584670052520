<template>
  <div>
    <b-row>
      <b-col>
        <label>使用者帳號</label>
        <b-form-input
          v-model="accountDetail.userAccount"
          disabled
        ></b-form-input>
      </b-col>
      <b-col>
        <label>使用者姓名</label>
        <b-form-input
          v-model="accountDetail.userName"
          disabled
        ></b-form-input>
      </b-col>
      <b-col>
        <label>所屬群組*</label>
        <b-form-select
          v-model="accountDetail.AG_ID"
          :options="groupOptions"
          disabled
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>電子郵件帳號</label>
        <b-form-input
          v-model="accountDetail.email"
          disabled
        ></b-form-input>
      </b-col>
    </b-row>
    <div class=" my-4 w-50">
      <b-card
        header-class="h5 px-3 py-2"
        no-body
        class="mb-3"
      >
        <template #header>
          請定期變更密碼，每6個月必須變更1次，且新密碼不得與舊密碼相同。<br />
          以下欄位均為必填欄位，密碼長度6個字元~12個字，以英文大小寫、數字等限定，需至少一位大寫、一位小寫、一位數字。
        </template>
        <b-table-simple hover small caption-top class="mb-0">
          <b-tr>
            <b-td>
              <label>請輸入您的新密碼*</label>
              <b-form-input
                type="password"
                v-model="pwd1"
                placeholder="新密碼"
                maxlength="12"
              ></b-form-input>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>
              <label>請再次輸入您的新密碼*</label>
              <b-form-input
                type="password"
                v-model="pwd2"
                placeholder="確認新密碼"
                maxlength="12"
              ></b-form-input>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="col-xs-1">
              <label>驗證碼*</label>
              <img
                :src="imageCode"
                alt="captchaImage"
                class="captcha"
                style="height: 50%" />
              <input type="tel" maxlength="6" class="passtext mx-2" v-model="code" />
              <a
                href="javascript:void(0)"
                class="reload"
                title="點我可重新產生驗證碼"
                @click="getImageCode"
                ><img src="/images/backend/icon-restart.png" alt="點我可重新產生驗證碼"
              /></a>
              <a
                href="javascript:void(0)"
                @click="speakWord"
                class="play"
                title="點我可播放語音服務"
                ><img src="/images/backend/icon-volume.png" alt="點我可播放語音服務"
              /></a>
              <audio id="vcode_mp3" src="/captcha.mp3?2022061703260100509"></audio
            >
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>
              <b-button @click="functionRestPwd">變更密碼</b-button>
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-card>
    </div>
  </div>
</template>

<script>
//語音套件
const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
export default {
  data() {
    return {
      pwd1: null,
      pwd2: null,
      code: null,
      imageCode: null,
      imageCodeArr: null,
      groupOptions: [],
      accountDetail: {},
      apiGetImageCode: (data) => this.userRequest.post("Eshntu/GetImageCode", data),
      apiUserInfo: (data) => this.userRequest.post("Eshntu/UserInfo", data),
    }
  },
  mounted() {
    this.init();
  },
  watch: {
  },
  methods: {
    init() {
      this.getImageCode();

      this.accountDetail = {};
      let json = {
        TYPE: "SELECT_DETAIL",
      };
      this.FunctionToken(this.GetAccountDetail, json);
      this.getGroupOption();
    },
    GetAccountDetail(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.accountDetail = jsonData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    speakWord() {
      this.handleSpeak(this.imageCodeArr);
    },
    //語音套件
    handleSpeak(text) {
      msg.text = text;
      msg.lang = "zh-CN";
      msg.voume = 1;
      msg.rate = 1;
      msg.pitch = 1;
      synth.speak(msg);
    },
    //變更帳號設定
    functionRestPwd() {
      if (this.pwd1 == null || this.pwd1 == "") {
        alert('密碼不得為空');
      } else {
        if (this.pwd1 === this.pwd2) {
          let json = {
            TYPE: "CHANGEPASS",
            user_info_RESET_PASS: {
              DecryptionPass: this.pwd1,
              ImgCodePass: this.code,
            },
          }
          this.FunctionToken(this.ResetPass, json);
        } else {
          alert('請確認密碼是否相同');
        }
      }
    },
    ResetPass(data) {
      this.apiUserInfo(data)
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            alert('變更成功');
            
            this.$router.go("/backend/Account/?type=9");
          } else {
            this.pwd1 = null;
            this.pwd2 = null;
            this.code = null;

            alert(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGroupOption() {
      let json = {
        TYPE: "GROUP_OPTION",
      };
      this.FunctionToken(this.functionGetGroupOption, json);
    },
    functionGetGroupOption(data) {
      this.apiUserInfo(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.groupOptions = [];

            if(this.initSeach) {
              this.groupOptions.push({ value: "", text: "請輸入所屬群組" });
            }
            // //console.log(jsonData)
            if (jsonData.length > 0) {
              jsonData.forEach((x) => {
                let obj = { value: x.key, text: x.value };
                this.groupOptions.push(obj);
              });
              if (this.initSeach && this.initGroup) {
                this.search.AG_ID = this.groupOptions[0].value;
              }
              else if (this.initGroup) {
                this.accountDetail.AG_ID = this.groupOptions[0].value;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getImageCode() {
      this.apiGetImageCode()
        .then((res) => {
          const json = JSON.parse(res.data);
          if (json.Status) {
            let jsonImage = JSON.parse(json.Data);
            this.imageCode = jsonImage.Image;
            this.imageCodeArr = jsonImage.Code.split("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
};
</script>