<template>
  <div class="mconarall">
    <Header :header="header"></Header>
    <div class="infall">
      <FAQ
        :contentId="contentId"></FAQ>
    </div>
  </div>
</template>

<script>
import Header from "../../components/admin/NavBar/Header.vue";
import FAQ from "../../components/admin/FAQ/Content.vue";
export default {
  components: {
    Header,
    FAQ,
  },
  data() {
    return {
      header: {
        text1: '駐警隊',
        text2: '常見問題維護'
      },
      contentId: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.query'() {
      this.init()
    }
  },
  methods: {
    init() {
      this.contentId = this.$route.query.id;
    },
  }
};
</script>