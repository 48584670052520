/**
 * Define our route selectable stylesheets
 *
 * @type {Object}
 */
const stylesheets = {
  esh:
    "/css/esh.css",
  en:
    "/css/esh_en.css",
  police:
    "/css/police.css",
  backside:
    "/css/backSideStyle.css"
};

const cssElement = document.getElementById("stylesheet");
const loadingElement = document.getElementById("loading");
/**
 * Set the default fallback stylesheet
 * @type {[type]}
 */
const defaultStylesheet = stylesheets.esh;

export default function stylesheet(to, from, next) {
  if (to.meta.stylesheet !== from.meta.stylesheet) {
    cssElement.href = stylesheets[to.meta.stylesheet] || defaultStylesheet;
    if(to.meta.stylesheet=="esh") {
      setTimeout(function () {
        loadingElement.classList.add("dispnone");
      }, 1000);
    } else {
      loadingElement.classList.add("dispnone");
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }

  return next();
}
