<template>
    <div class="topview">
        <div class="tpcbebg01">
            <div class="buid01"></div>
            <div class="buid02"></div>
            <div class="buid02_2"></div>
            <div class="buid03"></div>
        </div>
        <div class="tpcbebg02">
            <div class="buid04"></div>
            <div class="buid05"></div>
        </div>
        <div class="tpcbebg03">
            <div class="buid06"></div>
        </div>
        
        <div class="tpqlnk">
            <span><a href="/">環安衛中心</a></span>｜<span><a href="https://www.ntu.edu.tw/" target="_blank" title="臺大官網(另開新視窗)">臺大首頁</a></span>
        </div>
        
        <div class="tplgmnu">
            <div class="ntulogo"><img src="/images/police/ntulogo.svg" alt=""/></div>
            <nav class="menu">
                <ul>
                    <li class="menu01"><a href="#/Police/News">最新消息</a></li>
                    <li class="menu02"><a href="#/Police/Lose">失物招領</a></li>
                    <li class="menu03"><a href="#/Police/Content?type=14">關於本隊</a></li>
                    <li class="menu04"><a href="#/Police/Content?type=15">服務項目</a></li>
                    <li class="menu05"><a href="#/Police/Content?type=16">常用連結</a></li>
                    <li class="menu06"><a href="#/Police/FAQ">常見問題</a></li>
                    <li class="menu07"><a href="#/Police/Download">下載專區</a></li>
                </ul>
            </nav>
            
            <!--========== 手機選單 ==========-->
                    <div class="tright">
                        <div id="trigger-overlay" class="in-burger"><span></span></div>
                    </div>

                    <div class="overlay overlay-hugeinc RWD_mnuopen">
                        <button type="button" class="overlay-close">Close</button>
                    <nav>
                            <nav>
                                <ul> 
                                    <li><a href="#/Police/News">最新消息</a></li>
                                    <li><a href="#/Police/Lose">失物招領</a></li>
                                    <li><a href="#/Police/Content?type=14">關於本隊</a></li>
                                    <li><a href="#/Police/Content?type=15">服務項目</a></li>
                                    <li><a href="#/Police/Content?type=16">常用連結</a></li>
                                    <li><a href="#/Police/FAQ">常見問題</a></li>
                                    <li><a href="#/Police/Download">下載專區</a></li>
                                </ul>
                            </nav>
                        </nav>
                    </div>
                    <!--=========================-->
            
            
        </div>
        
        <div class="mtitadserv">
            <div class="mtitbx">
                <div class="mtit01"><img src="/images/police/mtit01.svg" alt=""/></div>  
                <div class="mtit02"><img src="/images/police/mtit02.svg" alt=""/></div>
                <div class="mtit03"><img src="/images/police/mtit03.svg" alt=""/></div>
            </div>
            <div class="mservice">
                <div class="msvblok msv01">
                    <a href="#/Police/Lose">
                        <div class="servp01"><img src="/images/police/per01.png" alt=""/></div>
                        <div class="servtt"><img src="/images/police/msertt01.png" alt=""/></div>
                    </a>
                </div>
                <div class="msvblok msv02">
                    <a href="#/Police/Content?type=14">
                        <div class="servp02"><img src="/images/police/per02.png" alt=""/></div>
                        <div class="servtt"><img src="/images/police/msertt02.png" alt=""/></div>
                    </a>
                </div>
                <div class="msvblok msv03">
                    <a href="#/Police/FAQ">
                        <div class="servp03"><img src="/images/police/per03.png" alt=""/></div>
                        <div class="servtt"><img src="/images/police/msertt03.png" alt=""/></div>
                    </a>
                </div>
            </div>
        </div>
        
        <div class="awdn"></div>
    </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props: ["header"],
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    init() {
    },
  },
};
</script>
