<template>
  <div>
    <DeleteModal :deleteModal="deleteModal" @init="init()"></DeleteModal>
    <div v-if="isPermission==false">資料讀取中</div>
    <div v-else-if="permission.isView">
      <div v-if="!detail">
        <b-row
          ><b-col>
            <b-form-input
              v-model="search.keywords"
              placeholder="請輸入關鍵字"
              maxlength="100"
            ></b-form-input></b-col
          >
          <b-col cols="1"
            ><b-button @click="init">
              <b-icon icon="search"></b-icon></b-button
          ></b-col>
          <b-col cols="2"
            ><b-button 
              @click="_go('?type='+typeSelect+'&id=null')"
              v-if="permission.isInsert"
              >新增</b-button
            ></b-col
          ></b-row
        >
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>標題</b-th>
              <b-th>異動人員</b-th>
              <b-th>異動日期</b-th>
              <b-th v-if="permission.isDelete || permission.isView"
                >修改</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in contentList" :key="item.contentId">
              <b-td>{{ item.title }}</b-td>
              <b-td>{{ item.modifierName }}</b-td>
              <b-td>{{ dateFormat(item.modifyDate) }}</b-td>
              <b-td v-if="permission.isDelete || permission.isView">
                <b-button
                  v-if="permission.isUpdate"
                  variant="info"
                  class="mr-3"
                  @click="_go('?type='+typeSelect+'&id=' + item.downloadId)"
                  >{{ (permission.isUpdate || permission.isInsert)  ? "編輯" : "檢視" }}</b-button
                >
                <a
                  href="javascript:"
                  v-if="permission.isDelete"
                  @click="functionDelete(item)"
                  ><img src="/images/backend/del_icon.png" alt="刪除icon"/></a
              ></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div class="page mt-2">
          <span class="pagination-total">共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料</span>
          <b-pagination
            class="pagination light-theme simple-pagination"
            v-model="pagination.page"
            :total-rows="pagination.rows"
            :per-page="pagination.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
      <div v-else>
        <div class="incsal">
          <div class="btare">
            <div class="backrul">
              <a :href="'#/backend/Download/?type=' + typeSelect">返回查詢結果</a>
            </div>
          </div>
          <b-row>
            <b-col
              ><label for="title">標題*</label>
              <b-form-input
                id="title"
                v-model="contentDetail.title"
                type="text"
                placeholder="請輸入標題"
                required
                maxlength="200"
              ></b-form-input
            ></b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>上架時間*</label>
              <b-form-datepicker 
                v-model="contentDetail.startDate"
                class="mb-2"
              ></b-form-datepicker
            ></b-col>
            <b-col>
              <label>下架時間</label>
              <b-form-datepicker
                v-model="contentDetail.endDate"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
            <b-col
              ><label>發布狀態*</label>
              <b-form-select
                v-model="contentDetail.deployStatus"
                :options="statusOptions"
              ></b-form-select
            ></b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>檔案</label>
              <div v-if="permission.isInsert">
                <b-button v-b-modal.modal-uploadFile>上傳檔案</b-button>
              </div>
              <b-modal
                id="modal-uploadFile"
                v-model="uploadFileShow"
                title="上傳檔案"
                ok-only
              >
                <template #modal-footer>
                  <b-overlay :show="uploading" rounded="sm">
                    <button
                      v-b-modal.modal-close_visit
                      class="btn btn-success btn-sm m-1"
                      @click="functionUploadFile"
                    >
                      上傳
                    </button>
                  </b-overlay>
                </template>
                <div>
                  <label>檔案上傳</label>
                  <b-form-file
                    accept=".doc,.docx,.xlsx,xls,.pdf,.odt,.ods,.mp4"
                    v-model="uploadFile.fileName"
                    ref="file-input"
                    class="mb-2"
                  ></b-form-file>
                </div>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-2">
              <ul v-if="contentDetail.outExFile != null && contentDetail.outExFile.length != 0">
                <li v-for="item in contentDetail.outExFile" :key="item.fileId" class="mt-2">
                  <div class="row">
                    <b-button
                      v-if="permission.isDelete"
                      @click="
                        fileId = item.fileId;
                        deleteConfirm = true;
                      "
                      class="mr-3"
                    >
                      刪除
                    </b-button>
                    <div style="color: black"><a :href="item.filePath" target="_blank">{{ item.fileName }}</a></div>
                  </div>
                </li>
              </ul>
              <div v-else>無檔案</div>
            </b-col>
          </b-row>
          <b-row
            ><b-col>
              <label>建檔人員</label>
              <b-form-input
                disabled="disabled"
                v-model="contentDetail.creatorName"
                class="mb-2"
              ></b-form-input></b-col
            ><b-col>
              <label>建檔日期</label>
              <b-form-datepicker
                disabled="disabled"
                v-model="contentDetail.createDate"
                class="mb-2"
              ></b-form-datepicker></b-col
          ></b-row>
          <b-row
            ><b-col>
              <label>異動人員</label>
              <b-form-input
                disabled="disabled"
                v-model="contentDetail.modifierName"
                class="mb-2"
              ></b-form-input></b-col
            ><b-col>
              <label>異動日期</label>
              <b-form-datepicker
                disabled="disabled"
                v-model="contentDetail.modifyDate"
                class="mb-2"
              ></b-form-datepicker></b-col
          ></b-row>
          <div v-if="permission.isInsert || permission.isUpdate" style="margin-top: 10px">
            <b-button @click="save">儲存</b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>無權限</div>
    <b-modal v-model="deleteConfirm" title="提示" ok-only>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click="DeleteFile()"
        >
          確定
        </button>
      </template>
      <p class="my-4">請問確定要刪除嗎?</p>
    </b-modal>
  </div>
</template>
<script>
  import DeleteModal from "../modal/deleteModal.vue";
  export default {
    components: {
      DeleteModal,
    },
    props: [ "contentId", "typeSelect" ],
    data() {
      return {
        formData: new FormData(),
        detail: false,
        search: {
          contentTagId: "",
          contentClassId: "",
        },
        initSeach: false,
        pagination: {
          perPage: 10,
          page: 1,
          rows: 5,
        },
        contentList: [],
        contentDetail: {},
        deleteConfirm: false,
        deleteModal: {
          id: null,
          show: false,
          type: "Download",
        },
        statusOptions: [
          { value: 1, text: '開啟' },
          { value: 2, text: '關閉' },
        ],
        uploadFileShow: false,
        fileId: null,
        uploading: false,
        uploadFile: {
          fileName: null,
        },
        apiDownload: (data) => this.userRequest.post("Eshntu/Download", data),
        apiDownloadFile: (data) => this.userRequest.post("Eshntu/DownloadFile", data),
        apiDownloadFile2: (data) => this.userRequest.post("Eshntu/DownloadFile2", data),
      };
    },
    computed: {},
    mounted() {
      this.init();
    },
    watch: {
      "$route.query"() {
        this.init();
      },
      "pagination.page"() {
        this.init();
      },
    },
    methods: {
      init() {
        if (this.typeSelect == undefined) {
          this.permission.isView = false;
        }

        //駐警隊-下載專區
        this.getPermission("PJ202409010034");

        if (this.contentId == undefined) {
          window.scrollTo(0, 0);
          this.detail = false;  
          this.initSeach = true;  
          this.contentList = {};
                        
          let json = {
            TYPE: "LIST",
            download_SELECT: {
              downloadTypeId: 1,
              keywords: this.search.keywords,
              pageIndex: this.pagination.page,
              pageSize: this.pagination.perPage,
            },
          };
          this.FunctionToken(this.GetContent, json);
        } else if (this.contentId == "null") {
          this.detail = true;  
          this.initSeach = false; 

          this.contentDetail = {
            tempId: this.GetUUID(),
            downloadTypeId: 1,
            startDate: new Date().toISOString(),
            deployStatus: 2,
            creatorName: sessionStorage.getItem("LoginName"),
            createDate: new Date(),
            modifierName: sessionStorage.getItem("LoginName"),
            modifyDate: new Date(),
            outExFile: null,
          };
        } else {
          this.detail = true;  
          this.initSeach = false; 
          this.contentDetail = {};

          let json = {
            TYPE: "SELECT",
            download_SELECT: {
              downloadId: this.contentId,
              downloadTypeId: 1,
            },
          };
          this.FunctionToken(this.GetContentDetail, json);
        }
      },
      GetContent(data) {
        this.apiDownload(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.contentList = jsonData.outData;
              this.pagination.rows = jsonData.totalCount;
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      GetContentDetail(data) {
        this.apiDownload(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.contentDetail = jsonData;

              this.getSelectFile();
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      functionDelete(item) {
        this.deleteModal.show = true;
        this.deleteModal.id = item.downloadId;
        this.deleteModal.type = "Download";
      },
      save() {
        if (this.contentId == "null") {
          let json = {
            TYPE: "INSERT",
            download_INSERT: {
              downloadTypeId: 1,
              tempId: this.contentDetail.tempId,
              title: this.contentDetail.title,
              startDate: this.contentDetail.startDate,
              endDate: this.contentDetail.endDate,
              deployStatus: this.contentDetail.deployStatus,
            },
          };
          this.FunctionToken(this.InsertContent, json);
        } else {
          let json = {
            TYPE: "UPDATE",
            download_UPDATE: {
              downloadId: this.contentId,
              downloadTypeId: 1,
              tempId: this.contentDetail.tempId,
              title: this.contentDetail.title,
              startDate: this.contentDetail.startDate,
              endDate: this.contentDetail.endDate,
              deployStatus: this.contentDetail.deployStatus,
            },
          };
          this.FunctionToken(this.UpdateContent, json);
        }
      },
      InsertContent(data) {
        this.apiDownload(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.$bvToast.toast("新增成功", {
                title: "提示",
                solid: true,
              });
              this.pagination.page = 1;
              this._go("?type=" + "1");
            } else {
              alert(JSON.parse(json.Data));
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      UpdateContent(data) {
        this.apiDownload(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this.$bvToast.toast("更新成功", {
                title: "提示",
                solid: true,
              });
              this._go("?type=" + "1");
            } else {
              alert(JSON.parse(json.Data));
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      functionUploadFile() {
        if (this.uploadFile.fileName == null) {
          alert('請上傳檔案');
        } else {
          this.uploading = true;

          this.formData.append("TYPE", "INSERT");
          this.formData.append("fileId", this.contentID);
          this.formData.append("tempId", this.contentDetail.tempId);
          this.formData.append("downloadTypeId", 1);
          this.formData.append("httpPostedFile", this.uploadFile.fileName);
          this.FunctionToken(this.functionInsertFile, {});
        }
      },
      functionInsertFile(data) {
        this.formData.append("Token", data.Token);
        this.apiDownloadFile2(this.formData)
          .then((res) => {
            let json = JSON.parse(res.data);
            this.formData = new FormData();
            if (json.Status) {
              this.$bvToast.toast('上傳成功', {
                title: '提示',
                solid: true
              })
              this.uploadFileShow = false;
              this.uploadFile = {};

              this.getSelectFile();
            } else {
              alert(JSON.parse(json.Data))
            }
            
            this.uploading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getSelectFile() {
        let json = {
          TYPE: "SELECT_TEMP",
          download_file_SELECT: {
            downloadId: null,
            tempId: this.contentDetail.tempId,
            downloadTypeId: 1,
          },
        }
        this.FunctionToken(this.functionSelectTempFile, json);
      },
      functionSelectTempFile(data) {
        this.apiDownloadFile(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.contentDetail.outExFile = jsonData;
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      DeleteFile() {
        this.deleteConfirm = false;
        let json = {
          TYPE: "DELETE",
          download_file_DELETE: {
            fileId: this.fileId,
            tempId: this.contentDetail.tempId,
            downloadTypeId: 1,
          },
        }
        this.FunctionToken(this.functionDeleteFile, json);
      },
      functionDeleteFile(data) {
        this.apiDownloadFile(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this.$bvToast.toast('刪除檔案成功', {
                title: '提示',
                solid: true
              })

              this.getSelectFile();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    }
  };

</script>
