<template>
  <div class="mconarall">
    <Header :header="header"></Header>
    <div class="infall">
      <PrivateContent
        :contentId="contentId"></PrivateContent>
    </div>
  </div>
</template>

<script>
import Header from "../../components/admin/NavBar/Header.vue";
import PrivateContent from "../../components/admin/PrivateContent/Content.vue";
export default {
  components: {
    Header,
    PrivateContent,
  },
  data() {
    return {
      header: {
        text1: '環安衛中心',
        text2: '加密頁面'
      },
      contentId: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.query'() {
      this.init()
    }
  },
  methods: {
    init() {
      this.contentId = this.$route.query.id;
    },
  }
};
</script>