<template>
  <div class="mconarall">
    <Header :header="header"></Header>
    <div class="infall">
      <Businss
        :contentId="contentId"
        :typeSelect="typeSelect"
        :contentType="contentType"></Businss>
    </div>
  </div>
</template>

<script>
import Header from "../../components/admin/NavBar/Header.vue";
import Businss from "../../components/admin/Business/Content.vue";
export default {
  components: {
    Header,
    Businss,
  },
  data() {
    return {
      header: {
        text1: '環安衛中心',
        text2: '主要業務'
      },
      typeSelect: null,
      contentType: null,
      contentId: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.query'() {
      this.init()
    }
  },
  methods: {
    init() {
      this.contentId = this.$route.query.id;
      let type = this.$route.query.type;
      if (type == undefined) {
        type = 0
      }
      this.typeSelect = parseInt(type);
      this.setActive();
    },
    setActive() {
      switch (this.typeSelect) {
        case 1:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '教育訓練';
          this.contentType = 1;
          break;
        case 2:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '健康管理';
          this.contentType = 1;
          break;
        case 3:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '保護計劃';
          this.contentType = 1;
          break;
        case 4:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '實驗場所';
          this.contentType = 1;
          break;
        case 5:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '廢棄物清運';
          this.contentType = 1;
          break;
        case 6:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '災害防救';
          this.contentType = 1;
          break;
        case 7:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '自動檢查';
          this.contentType = 1;
          break;
        case 8:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '其他業務';
          this.contentType = 1;
          break;
        case 9:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '環安衛宣導';
          this.contentType = 1;
          break;
        case 10:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '相關委員會';
          this.contentType = 1;
          break;
        case 11:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '中心簡介';
          this.contentType = 3;
          break;
        case 12:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '法規輯要';
          this.contentType = 4;
          break;
        case 13:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '文件下載';
          this.contentType = 5;
          break;
        case 14:
          this.header.text1 = '駐警隊';
          this.header.text2 = '關於本隊';
          this.contentType = 6;
          break;
        case 15:
          this.header.text1 = '駐警隊';
          this.header.text2 = '服務項目';
          this.contentType = 7;
          break;
        case 16:
          this.header.text1 = '駐警隊';
          this.header.text2 = '常用連結';
          this.contentType = 8;
          break;
        case 17:
          this.header.text1 = '環安衛中心英文版';
          this.header.text2 = 'Training';
          this.contentType = 9;
          break;
        case 20:
            this.header.text1 = '環安衛中心英文版';
            this.header.text2 = 'About EHS';
          this.contentType = 10;
            break;
        case 21:
          this.header.text1 = '環安衛中心英文版';
          this.header.text2 = 'Work Group';
          this.contentType = 11;
          break;
        case 18:
          this.header.text1 = '環安衛中心英文版';
          this.header.text2 = 'Health Management';
          this.contentType = 9;
          break;
        case 19:
          this.header.text1 = '環安衛中心英文版';
          this.header.text2 = 'Laboratories';
          this.contentType = 9;
          break;
      }
    },
  }
};
</script>