<template>
  <div>
    <div class="con_footer_testHeader">Copyright 2024. All Rights Reserved. 版權所有翻印必究；請勿拷貝或轉載於任何媒體</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    init() {
       
    },
  },
};
</script>
